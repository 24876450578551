import React from 'react'
import {deleteIcon, editIcon} from "./SvgIcons";
import ClientRow from "./units/ClientRow";


export default function ClientGrid(props) {
    return (
        <div className="flex flex-col w-full">
            <div className="w-full">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="min-w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Denomination Social
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Contact

                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    adresse
                                </th>
                                {/* <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Status
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Role
                                </th> */}
                                <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    <span className="">Actions</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {props.clients.map((person) => (
                                 <ClientRow  key={person.id}
                                             client={person}
                                             onEdit={(client) => {
                                                 if (props.onEdit && typeof props.onEdit === "function") {
                                                     props.onEdit(client)
                                                 }
                                             }}
                                             onDelete={(client) => {
                                                 if (props.onDelete && typeof props.onDelete === "function") {
                                                     props.onDelete(client)
                                                 }
                                             }}
                                 ></ClientRow>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

ClientGrid.defaultProps = {
    employeurs: [],
    label: "Button Text"
};