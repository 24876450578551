import React, {useEffect, useState} from "react"
import {useNavigate, useLocation} from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import ClientSelector from "./mini/ClientSelector";
import ClientService from "../Services/ClientService";
import FileSelector from "./mini/FileSelector";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
import ModalClientForm from "./ModalClientForm";
import EmployeurSelector from "./mini/EmployeurSelector";
import EmployeurService from "../Services/EmployeurService";
import ModalEmployeurForm from "./ModalEmployeurForm";
import MySwal from "sweetalert2";
import Field from "./mini/Field";
import {cancelIcon, validateIcon} from "../mini/SvgIcons";

registerLocale("fr", fr);

export default function MissionForm(props) {
    let location = useLocation();
    let open, openEmp;
    let isFormValid = () => {
        return true;
    }
    let submitForm = function () {
        if (isFormValid()) {
            let m = {...mission};
            m.ClientId = client.id;
            m.ConsultantId = consultant.id;
            m.EmployeurId = employeur.id;
            const formData = new FormData();
            for (const name in m) {
                formData.append(name, m[name]);
            }
            formData.append('contrat', contrat);
            formData.append('avenant', avenant);
            if (action == "ADD") {
                fetch("/api/mission", {
                    method: "POST",
                    body: formData,
                    headers: {
                        "Authorization": "bearer " + localStorage.getItem("token")
                    }
                }).then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 401) {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Authentification Erreur',
                            text: "Vous avez ete deconnecte",
                        })
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(json => {
                    if (json.success) {
                        navigate("/consultant");
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                });
            } else {
                //UPDATE
                fetch("/api/mission", {
                    method: "PUT",
                    body: formData,
                    headers: {
                        "Authorization": "bearer " + localStorage.getItem("token")
                    }
                }).then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status === 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }
                }).then(json => {
                    if (json.success) {
                        navigate("/missions");
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                });
            }
        }
    }
    const navigate = useNavigate();
    let [contrat, setContrat] = useState(null)
    let [avenant, setAvenant] = useState(null)

    let cliService = new ClientService()
    let empService = new EmployeurService()
    let [clients, setclients] = useState([])
    let [employeurs, setEmployeurs] = useState([])
    let recevedMission, recevedClient, recevedEmployeur, action;
    // let mission = location.state;
    let consultant = location.state;
    if (location.state.Consultant && location.state.Consultant.id) {
        // midification
       // console.log(location.state.backTo)
        consultant = location.state.Consultant;
        action = "UPDATE"
        recevedMission = location.state;
        recevedClient = recevedMission.Client;
        recevedEmployeur = recevedMission.Employeur;

    } else {
        //creation
        action = "ADD"
        recevedMission = {};
        recevedClient = null;
        recevedEmployeur = null;

    }


    let [mission, setMission] = useState(recevedMission);
    let [client, setclient] = useState(recevedClient)
    let [employeur, setEmployeur] = useState(recevedEmployeur)

    useEffect(() => {
        cliService.getAll().then(json => {
            setclients(json.data)
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });
        empService.getAll().then(json => {
            setEmployeurs(json.data)
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });
    }, [])

    // {JSON.stringify(consultant)}
    // let setSelectedClient = setSelectedClient || null;
    return <div className={"grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200"}>
        <ModalClientForm getTools={t => {
            open = t.setOpen;
        }} aftersave={c => {
            setclient(c);
        }}></ModalClientForm>
        <ModalEmployeurForm getTools={t => {
            openEmp = t.setOpen;
        }} aftersave={cl => {
            setEmployeur(cl);
        }}></ModalEmployeurForm>

        <div role="infos" className={"col-span-2 flex justify-center"}>
            Mission pour : {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}
        </div>
        <div role="Form" className={"col-span-2"}>
            <form autoComplete="off" className="flex flex-col justify-center p-10">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <EmployeurSelector
                        ro={action == "UPDATE"}
                        autoFocus={true}
                        onclear={e => {
                            setEmployeur(null);
                        }}
                        onaddNewClient={e => openEmp(true)}
                        clients={employeurs}
                        onselect={e => {
                            setEmployeur(e)
                        }}
                        client={employeur}
                    ></EmployeurSelector>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <ClientSelector
                        ro={action == "UPDATE"}
                        onclear={e => {
                            setclient(null);

                        }}
                        onaddNewClient={e => open(true)}
                        clients={clients}
                        onselect={cli => {
                            setclient(cli)
                        }}
                        client={client}
                    ></ClientSelector>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6">

                    <div className="w-full px-3">
                        {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                        {/*       htmlFor="grid-last-name">*/}
                        {/*    */}
                        {/*</label>*/}
                        <Field
                            label={"Titre de la mission"}
                            value={mission.title || ""}
                            required={true}
                            enteraction={submitForm}
                            onChange={e => {
                                let em = {...mission};
                                em.title = e.target.value;
                                setMission(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: DEVOPS, TESTEUR, DEV ...."/>
                    </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6">

                    <div className="w-full md:w-5/12 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-last-name">
                            Date Debut
                        </label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="fr"
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-green-500 px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            id="grid-start-date"
                            selected={Date.parse(mission.dateStart)}
                            onChange={e => {
                                let em = {...mission};
                                em.dateStart = e;
                                setMission(em);
                            }}/>
                    </div>
                    <div className="w-full md:w-5/12 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-last-name">
                            Date fin
                        </label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            locale="fr"
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-green-500 px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            id="grid-start-date"
                            enteraction={submitForm}
                            selected={Date.parse(mission.dateEnd)}
                            onChange={e => {
                                let em = {...mission};
                                em.dateEnd = e;
                                setMission(em);
                            }}/>
                        {/* <input
                            value={mission.dateEnd || ""}
                            required={true}
                            onChange={e => {
                                let em = { ...mission };
                                em.dateEnd = e.target.value;
                                setMission(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: ayoub" /> */}
                    </div>
                    <div className="w-full md:w-2/12 px-3 mb-6">
                        <Field
                            label={"tjm"}
                            value={mission.tjm || ""}
                            required={true}
                            enteraction={submitForm}
                            onChange={e => {
                                let em = {...mission};
                                em.tjm = e.target.value;
                                setMission(em);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: ayoub"/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-6/12 px-3">
                        <FileSelector label={"Contrat"} onchose={(f) => {
                            setContrat(f)
                        }} helper={"Veillez selectionner le Contrat"}></FileSelector>
                    </div>
                    <div className="w-full md:w-6/12 px-3">
                        <FileSelector label={"Avenant"} onchose={(f) => {
                            setAvenant(f)
                        }} helper={"Seulement si il y a un avenant au Contrat"}></FileSelector>
                    </div>
                </div>
                {/* <div className="flex flex-wrap -mx-3 mb-6">
                    <FileSelector label={"Avenant"} clients={clients} ></FileSelector>
                </div> */}
                <div className="pt-1  flex justify-between">
                    <button
                        className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        data-cy={"mission-form-btn-cancel"}
                        title={"Annuler"}
                        onClick={() => {
                            if (location.state.backTo) {
                                navigate(location.state.backTo)
                            } else {
                                navigate('/consultant')
                            }
                        }}>{cancelIcon}
                    </button>
                    <button
                        className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        data-cy={"mission-form-btn-validate"}
                        title={"Valider"}
                        onClick={() => {
                            submitForm();
                        }}
                    >{validateIcon}
                    </button>
                </div>
            </form>

        </div>

    </div>
}
