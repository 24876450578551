import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "./Page";
import ClientService from "../Services/ClientService";
import ClientGrid from "../mini/ClientGrid";
import ActionsService from "../Services/ActionsService";
import ActionGrid from "../mini/ActionsGrid";


export default function Actions() {
    let [Actions, setActions] = useState([]);
    let service = new ActionsService();
    useEffect(() => {
        service.getAll().then(json => {
            if ( json.success ) {
                setActions(json.data);
            }
        })
    }, [])

    return <Page>
        {/*{JSON.stringify(Actions,null,4)}*/}
        <ActionGrid actions={Actions}></ActionGrid>
        {/*<ClientGrid*/}
        {/*    onEdit={e=>{*/}
        {/*        navigate('/clients/create/'+e.id)*/}
        {/*    }}*/}
        {/*    onDelete={deleteClient}*/}
        {/*    clients={clients}></ClientGrid>*/}
    </Page>;
}
