import React, {Fragment, useState} from 'react'
import Modal from "../mini/Modal";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, SelectorIcon} from "@webologie/react-icons/outline";

const options = [];
options.push({
    id: 'none',
    name: "Nombre de jours ?",
})
for (let i = 0; i <= 230; i += 5) {
    options.push({
        id: i,
        name: i / 10,
    })
}
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
export default function PrevOverlayForm(props) {
    const [prev, setPrev] = useState({})
    // let service = new ClientService();
    let getSelectDays = function () {

        return (<>
                <div className="pb-3 h-80">
                    <Listbox value={prev.nbJours} onChange={e => {
                      //  console.log(e)
                        let p = {...prev};
                        p.nbJours = e.name;
                        setPrev(p);
                    }}>
                        {({open}) => (
                            <>
                                <Listbox.Label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Nombre de jours
                                    travaille</Listbox.Label>
                                <div className="mt-1 relative">
                                    <Listbox.Button
                                        className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                    <span className="flex items-center">
                                        <span className="ml-3 block truncate">{prev.nbJours}</span>
                                    </span>
                                        <span
                                            className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                    </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options
                                            className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {options.map((person) => (
                                                <Listbox.Option
                                                    key={person.id}
                                                    className={({active}) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'cursor-default select-none relative py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={person}
                                                >
                                                    {({selected, active}) => (
                                                        <>
                                                            <div className="flex items-center">
                                                            <span
                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                            >
                                                                {person.name}
                                                            </span>
                                                            </div>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-indigo-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                                            </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
            </>

        )
    }
    return (
        <Modal title={'Modification Previsionnel'}
               onValidate={(e, closeFn) => {
                   props.onValidate(prev,closeFn)
                   // closeFn(false);
               }}
               getTools={({ setOpen }) => {
                   props.getTools({ setOpen,setPrev });
                   // setModalOpen = setOpen;
               }}>

            <div className="w-full px-3">
                {getSelectDays()}
                {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                {/*       htmlFor="grid-denomination-sociale">*/}
                {/*    Nb Jours travaille*/}
                {/*</label>*/}
                {/*<input*/}
                {/*    value={prev.nbJours || ""}*/}
                {/*    required={true}*/}
                {/*    onChange={e => {*/}
                {/*        let p = {...prev};*/}
                {/*        p.nbJours = e.target.value;*/}
                {/*        setPrev(p);*/}
                {/*    }}*/}
                {/*    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
                {/*    id="grid-denomination-sociale" type="text" placeholder="ex: Citeo" />*/}
            </div>


        </Modal>
    )
}
