import React, {useState} from 'react'
import Modal from "../mini/Modal";
import EmployeurService from '../Services/EmployeurService';
import Field from "./mini/Field";
import MySwal from "sweetalert2";


export default function ModalEmployeurForm(props) {
    const [client, setClient] = useState({})
    let service = new EmployeurService();
    let setModalOpen;
    let submitForm = () => {
        if (isValid()) {
            service.insert(client).then(res => {
                if (res.success) {
                    props.aftersave && props.aftersave(res.data);
                    setModalOpen(false)
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res.message,
                    })
                }
            });
        }
    }
    let denominationSocialF, rcsF, siretF, presidentF, tvaIntracomF, contactF, contactPhoneF, contactMailF, addressF,
        zipcodeF, cityF;
    let isValid = () => {
        return denominationSocialF && denominationSocialF.isValid() &&
            rcsF && rcsF.isValid() &&
            siretF && siretF.isValid() &&
            presidentF && presidentF.isValid() &&
            tvaIntracomF && tvaIntracomF.isValid() &&
            contactF && contactF.isValid() &&
            contactPhoneF && contactPhoneF.isValid() &&
            contactMailF && contactMailF.isValid() &&
            addressF && addressF.isValid() &&
            zipcodeF && zipcodeF.isValid() &&
            cityF && cityF.isValid();
    }


    return (<Modal title={'Creation de nouvel employeur'}
                   onValidate={(e, closeFn, email) => {
                       setModalOpen = closeFn;
                       submitForm();
                   }}
                   getTools={({setOpen}) => {
                       setModalOpen = setOpen;
                       props.getTools({setOpen});
                   }}>

        <div className="w-full px-3">
            <Field
                label={"Dénomination Social de l'employeur"}
                value={client.denominationSocial || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.denominationSocial = e.target.value;
                    setClient(em);
                }}
                ref={(r) => {
                    denominationSocialF = r;
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-denomination-sociale" type="text" placeholder="ex: Citeo"/>
        </div>

        <div className="w-full px-3">
            <Field
                label={"RCS"}
                value={client.rcs || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.rcs = e.target.value;
                    setClient(em);
                }}
                ref={(r) => {
                    rcsF = r;
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: Ville 000 000 000"/>
        </div>


        <div className="w-full  px-3">
            <Field
                label={"Siret"}
                value={client.siret || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.siret = e.target.value;
                    setClient(em);
                }}
                ref={(r) => {
                    siretF = r;
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: 84074430400022"/>
        </div>

        <div className="w-full px-3">
            <Field
                label={"Président"}
                ref={(r) => {
                    presidentF = r;
                }}
                value={client.president || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.president = e.target.value;
                    setClient(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: jean mouloud de montalambere"/>
        </div>
        <div className="w-full  px-3">
            <Field
                label={"TVA intercom."}
                ref={(r) => {
                    tvaIntracomF = r;
                }}
                value={client.tvaIntracom || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.tvaIntracom = e.target.value;
                    setClient(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: FR0000000000"/>
        </div>


        <div className="w-full  px-3">
            <Field
                label={"contact"}
                ref={(r) => {
                    contactF = r;
                }}
                value={client.contact || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.contact = e.target.value;
                    setClient(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: moha begdadi"/>
        </div>


        <div className="w-full px-3">
            <Field
                label={"contact phone"}
                ref={(r) => {
                    contactPhoneF = r;
                }}
                value={client.contactPhone || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.contactPhone = e.target.value;
                    setClient(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: 0600000000"/>
        </div>
        <div className="w-full px-3">
            <Field
                label={"contact email"}
                value={client.contactMail || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.contactMail = e.target.value;
                    setClient(em);
                }}
                ref={(r) => {
                    contactMailF = r;
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: user@exaple.com"/>
        </div>

        <div className="w-full  px-3">
            <Field
                label={"Address"}
                value={client.address || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.address = e.target.value;
                    setClient(em);
                }}
                ref={(r) => {
                    addressF = r;
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex:  12 rue de la reine Amelie"/>
        </div>
        <div className="w-full px-3">
            <Field
                label={"code postal"}
                value={client.zipCode || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.zipCode = e.target.value;
                    setClient(em);
                }}
                ref={(r) => {
                    zipcodeF = r;
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: 94000"/>

        </div>
        <div className="w-full  px-3">
            <Field
                label={"Ville"}
                ref={(r) => {
                    cityF = r;
                }}
                value={client.city || ""}
                required={true}
                onChange={e => {
                    let em = {...client};
                    em.city = e.target.value;
                    setClient(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-last-name" type="text" placeholder="ex: Bobiny"/>
        </div>
    </Modal>);
}