import React, {useEffect, useState} from "react"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AuthManager from "../secu/AuthManager";
// import EmployeurService from "../Services/EmployeurService";
// import ClientService from "../Services/ClientService";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
registerLocale("fr", fr);
import "./react-datepicker.scss";
import ConsultantService from "../Services/ConsultantService";
import Field from "./mini/Field";
import Loader from "../../tools/Loader";
import {cancelIcon, validateIcon} from "../mini/SvgIcons";


export default function MiniConsultantForm(props) {

    const navigate = useNavigate();
    let location = useLocation()
    let params = useParams()
    let consultantToEdit = location.state;
    let firstnameF, lastnameF, emailF, phoneF, dateNaissanceF, secuF, addressF, zipcodeF, cityF;
    let isFormValid = () => {
        return firstnameF && firstnameF.isValid() &&
            lastnameF && lastnameF.isValid() &&
            emailF && emailF.isValid() &&
            phoneF && phoneF.isValid() &&
            secuF && secuF.isValid() &&
            addressF && addressF.isValid() &&
            zipcodeF && zipcodeF.isValid() &&
            cityF && cityF.isValid();
    }
    let submitForm = function () {
        if (isFormValid()) {
            if (params && params.id) {
                //je suis en modif
                Loader.loadstart(`Mise a jour de ${consultant.firstname}  ${consultant.lastname}`)

                service.update(consultant).then(json => {
                    if (json.success) {
                       // console.log(json)
                        navigate("/consultant");
                    }
                }).finally(e=>{
                    Loader.loadstop();
                })
            } else {
                //je suis en ajout
                Loader.loadstart(`Creation de ${consultant.firstname}  ${consultant.lastname}`)

                fetch("/api/consultant", {
                    method: "POST",
                    body: JSON.stringify(consultant),
                    headers: {
                        "content-type": 'application/json',
                        "Authorization": "bearer " + localStorage.getItem("token")
                    }
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else if (response.status == 401) {
                            AuthManager.desAuthenticateUser();
                            window.location.href = '/login';
                        }

                    })
                    .then(json => {
                        if (json.success) {
                            navigate("/consultant");
                        } else {
                            alert(json.message)
                        }
                    })
                    .catch(err => {
                        console.error(err)
                    }).finally(e=>{
                    Loader.loadstop();
                })
            }

        }


    }
    let [consultant, setConsultant] = useState(consultantToEdit || {

        Utilisateur: {
            firstname: "",
            lastname: "",
            login: '',
        },
        Missions: {
            Clients: [],
            Employeurs: []
        }
    });
    // const [startDate, setStartDate] = useState(new Date());
    // let [employeurID, setEmployeurID] = useState(0);
    // let [missionID, setMissionID] = useState(0);
    let service = new ConsultantService();
    // let cservice = new ClientService();
    // let eService = new EmployeurService();

    useEffect(() => {
        if (params && params.id) {
            // service.getOne(props.match.params.id).then(json => {
            //     if (json.success) {
            //         setConsultant(json.data[0]);
            //         console.log(json.data[0]);
            //     }
            // })
        }
        // cservice.getAll().then(json => {
        //     if (json.success) {
        //         setClients(json.data);
        //     }
        // })
        // eService.getAll().then(json => {
        //     if (json.success) {
        //         setEmployeurs(json.data);
        //     }
        // })
    }, [])
    // let [clients, setClients] = useState([])
    // let [employeurs, setEmployeurs] = useState([])
    // let [src, setSrc] = useState("/img/profile.jpg");
    // let [photo, setPhoto] = useState(null);

    return <div className={"grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200 px-12"}>
        <div role="Form" className={"col-span-2"}>
            <form className="flex flex-col justify-center p-10">

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Infos du consultant:</h3>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

                        <Field
                            label={"Nom"}
                            data-cy={"consultant-firstname"}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            required={true}
                            min={3}
                            max={150}
                            ref={(r) => {
                                firstnameF = r;
                            }}
                            value={consultant.Utilisateur.firstname || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.Utilisateur.firstname = e.target.value;
                                setConsultant(c);
                            }}
                            enteraction={submitForm}
                            type="text"
                            placeholder="ex: Dupont"/>
                        {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"Prénom"}
                            id="grid-last-name"
                            data-cy={"consultant-lastname"}
                            ref={(r) => {
                                lastnameF = r;
                            }}
                            value={consultant.Utilisateur.lastname || ""}
                            required={true}
                            onChange={e => {
                                let c = {...consultant};
                                c.Utilisateur.lastname = e.target.value;
                                setConsultant(c);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="text" placeholder="ex: Romain"/>
                    </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3 md:w-1/2">
                        {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                        {/*       htmlFor="grid-email">*/}
                        {/*    email*/}
                        {/*</label>*/}
                        <Field
                            label={"email"}
                            id="grid-last-email"
                            data-cy={"consultant-email"}
                            vtype={"email"}
                            required={true}
                            ref={(r) => {
                                emailF = r;
                            }}
                            enteraction={submitForm}
                            value={consultant.Utilisateur.login || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.Utilisateur.login = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="email" placeholder="ex: user@exaple.com"/>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                        {/*       htmlFor="grid-city">*/}
                        {/*    Telephone*/}
                        {/*</label>*/}
                        <Field
                            label={"Téléphone"}
                            data-cy={"consultant-phone"}
                            ref={(r) => {
                                phoneF = r;
                            }}
                            enteraction={submitForm}
                            required={true}
                            value={consultant.Utilisateur.phone || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.Utilisateur.phone = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-city"
                            vtype={"phone"}
                            placeholder="0600000000"/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3 md:w-1/2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-email">
                            Date de naissance
                        </label>
                        <DatePicker
                            data-cy={"consultant-birth-date"}
                            dateFormat="dd/MM/yyyy"
                            locale="fr"
                            className="block appearance-none w-full bg-gray-200 text-gray-700 border border-green-500 px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                            id="grid-birth-date"
                            selected={Date.parse(consultant.birthDate)}
                            onChange={e => {
                               // console.log(e)
                                let c = {...consultant};
                                c.birthDate = e;
                                setConsultant(c);
                            }}/>
                        {/* <input
                            required={true}
                            value={consultant.login || ""}
                            onChange={e => {
                                let c = { ...consultant };
                                c.Consultant.birthDate = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-email" type="email" placeholder="ex: user@exaple.com" /> */}
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

                        <Field
                            label={"Numéro de sécurité sociale"}
                            data-cy={"consultant-Secu"}
                            ref={(r) => {
                                secuF = r;
                            }}
                            enteraction={submitForm}
                            required={true}
                            value={consultant.secuNum || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.secuNum = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-city"
                            placeholder="0600000000"/>

                    </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3 md:w-1/2">
                        {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                        {/*       htmlFor="grid-email">*/}
                        {/*    Adresse complète*/}
                        {/*</label>*/}
                        <Field
                            label={"Adresse complète"}

                            data-cy={"consultant-addr"}
                            ref={(r) => {
                                addressF = r;
                            }}
                            enteraction={submitForm}
                            required={true}
                            value={consultant.address || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.address = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-email" type="email" placeholder="ex: 10 rue de la paix"/>
                    </div>
                    <div className="w-full px-3 md:w-1/4">
                        {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                        {/*       htmlFor="grid-email">*/}
                        {/*    Code postal*/}
                        {/*</label>*/}
                        <Field
                            label={"Code postal"}

                            vtype="codePost"
                            data-cy={"consultant-cp"}
                            ref={(r) => {
                                zipcodeF = r;
                            }}
                            enteraction={submitForm}
                            required={true}
                            value={consultant.zipCode || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.zipCode = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-email" type="email" placeholder="ex: 78320"/>
                    </div>
                    <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                        {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                        {/*       htmlFor="grid-city">*/}
                        {/*    Ville*/}
                        {/*</label>*/}
                        <Field
                            label={"ville"}
                            data-cy={"consultant-city"}
                            ref={(r) => {
                                cityF = r;
                            }}
                            enteraction={submitForm}
                            required={true}
                            value={consultant.city || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.city = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-city"
                            type="text"
                            pattern={"[0-9]"}
                            step="0.01"
                            placeholder="Ex: Poissy"/>

                    </div>
                </div>


                <div className="pt-5 flex justify-between">
                    <button
                        className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        data-cy={"consultant-form-btn-cancel"}
                        title={"Annuler"}
                        onClick={e => {
                            navigate("/consultant");
                        }}
                        title={"Annuler et revenir a la liste des consultants"}
                    >  {cancelIcon}
                    </button>
                    <button
                        className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        data-cy={"consultant-form-btn-validate"}
                        title={"Valider"}
                        onClick={submitForm}
                        title={(params && params.id) ? "Valider la modification du consultant":"Valider l'ajout du consultant"}>
                        {validateIcon}
                    </button>
                </div>

            </form>


        </div>
    </div>
}