/* This example requires Tailwind CSS v2.0+ */
import React, {useState} from 'react'
import Modal from "../mini/Modal";
import MySwal from "sweetalert2";

import Field from "./mini/Field";
import PrevService from "../Services/PrevService";


export default function ModalCrteateActivityForm(props) {
    let setModalOpen;
    const [mission, setMission] = useState({});
    const [year, setYear] = useState();
    const [mounth, setMounth] = useState();
    let service = new PrevService();


    let submitForm = () => {
        if (isValid()) {
            // console.log()
            // mission.tjm = mission.tjm+""
            service.createNewActivities({id:mission.id,year, mounth}).then(res => {
                if (res.success) {
                    props.aftersave && props.aftersave(res.data);
                    setModalOpen(false)
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res.message,
                    })
                }
            });
        }

    }
    let isValid = () => {
        return true;
    }
    return (<Modal title={'Creation de nouveau client'}
                   onValidate={(e, closeFn, miss) => {
                       console.log(mission)
                       setModalOpen = closeFn;
                       submitForm();
                   }}
                   getTools={({setOpen}) => {
                       setModalOpen = setOpen;
                       props.getTools({setOpen, setMission});
                       // setModalOpen = setOpen;
                   }}>

        <div className="w-full px-3" style={{minHeight: "300px"}}>
            <Field
                label={"Mois"}
                value={mounth || ""}
                // ref={(r) => {
                //     dateEndF = r;
                // }}
                required={true}
                onChange={e => {
                    setMounth(e.target.value)
                    // let em = {...mission};
                    // em.dateEnd = e;
                    // setMission(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-denomination-sociale" type="text" placeholder="ex: 11"/>

            <Field
                label={"Annee"}
                value={year || ""}
                // ref={(r) => {
                //      dateEndF = r;
                // }}
                required={true}
                onChange={e => {
                    setYear(e.target.value);
                    // let em = {...mission};
                    // em.dateEnd = e;
                    // setMission(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-denomination-sociale" type="text" placeholder="ex: 2022"/>
        </div>
    </Modal>)
}