import React, {useEffect} from "react";
import {Chart, registerables} from 'chart.js';
import * as Utils from "../ChartUtils";
import ConsultantService from "../../Services/ConsultantService";

Chart.register(...registerables);
const DATA_COUNT = 12;
const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

const labels = Utils.months({count: 12});
const data = {
    labels: labels,
    datasets: [
        {
            label: 'CA des 12 derniers mois',
            data: Utils.numbers(NUMBER_CFG),
            backgroundColor: "#0865FA",
        }
    ]
};

export function CAChart(props) {
    if (props.consultant.id) {
        useEffect(() => {
           // console.log(props.consultant)
            let cachart;
            if (props.consultant.id) {
                let service = new ConsultantService();
                service.getCaData(props.consultant.id).then(json => {
                    //console.log(json)
                    let ctx = document.getElementById('CAChart').getContext('2d');
                    cachart = new Chart(ctx, {
                        type: 'bar',
                        data: {
                            labels: labels,
                            datasets: [
                                {
                                    label: 'CA de l\'annee '+new Date(Date.now()).getFullYear(),
                                    data: json.data,
                                    backgroundColor: "#0865FA",
                                }
                            ]
                        },
                        options: {
                            plugins: {
                                tooltip:{
                                    afterTitle:"Euros",
                                    callbacks: {
                                        label: function(context) {
                                            let label = context.label || '';

                                            if (label) {
                                                label += ': ';
                                            }
                                            if (context.parsed.y !== null) {
                                                label += new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(context.parsed.y);
                                            }
                                            return label;
                                        }
                                    }
                                },
                                title: {
                                    display: false,
                                    text: 'Previsioonels / Consultants'
                                },
                            },
                            responsive: true,
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    beginAtZero: true,
                                    stacked: true
                                }
                            }
                        }
                    });
                })
            }


            return () => {
                cachart.destroy()
            }

        }, [])
    }


    return <div style={{width: '100%', height: '400px'}}>
        <canvas style={{width: '100%', maxHeight: '400px'}} id="CAChart"/>
    </div>;

}