import {deleteIcon, editIcon} from "../SvgIcons";
import React from "react";

export default function EmployeurRow(props) {


let person = props.employeur;
    return <tr onDoubleClick={e => {
        e.preventDefault();
        if (props.onEdit && typeof props.onEdit === "function") {
            props.onEdit(person)
        }
    }}>
        <td className="px-6 py-2 whitespace-nowrap">

            <div
                className="text-sm font-medium text-gray-900"> {person.denominationSocial} </div>
            <div className="text-sm text-gray-500">{person.siret}</div>
            <div className="text-sm text-gray-500">{person.rcs}</div>

        </td>
        <td className="px-6 py-2 whitespace-nowrap">
            <div className="text-sm text-gray-900">{person.contact}</div>
            <div className="text-sm text-gray-500">{person.contactPhone}</div>
            <div className="text-sm text-gray-500">{person.contactMail}</div>

        </td>
        {/* <td className="px-6 py-4 whitespace-nowrap ">
                                        <div className="text-sm  text-gray-900">{person.address}</div>
                                        <div
                                            className="text-sm  text-gray-500">{person.zipCode} {person.city}</div>
                                    </td> */}

        {/* <td className="px-6 py-4 whitespace-nowrap w-1">
                                      <span
                                          className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                        Active
                                      </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1">{person.type}</td> */}
        <td className="px-6 py-2 whitespace-nowrap text-right text-sm font-medium w-1">
            <button
                className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={`Editer l'employeur : ${person.denominationSocial} `}
                onClick={e => {
                    e.preventDefault();
                    if (props.onEdit && typeof props.onEdit === "function") {
                        props.onEdit(person)
                    }
                }}
            >
                {editIcon}
                {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"*/}
                {/*     viewBox="0 0 24 24" stroke="currentColor">*/}
                {/*    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}*/}
                {/*          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>*/}
                {/*</svg>*/}
            </button>
            <button
                className="bg-red-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={`Supprimer l'employeur : ${person.denominationSocial} `}
                onClick={e => {
                    e.preventDefault();
                    if (props.onDelete && typeof props.onDelete === "function") {
                        props.onDelete(person)
                    }
                }}
            >
                {deleteIcon}
            </button>
        </td>
    </tr>
}