import React, {useContext } from "react";
import Page from "./Page";
import AdminDash from "../mini/AdminDash";
import AuthenticationContext from "../secu/AuthenticationContext";
import ConsultantsDash from "../mini/ConsultantsDash";

export default function DashBoard() {
    let AuthContext = useContext(AuthenticationContext);
    return <Page>
        {AuthContext.isAdmin() && <AdminDash></AdminDash>}
        {AuthContext.isConsultant() && <ConsultantsDash></ConsultantsDash>}
    </Page>;
}
