import React, { useContext, useEffect, useState } from "react";
import Profile from "../mini/Profile";
import Page from "./Page";
import AuthenticationContext from "../secu/AuthenticationContext";
import UserService from "../Services/UserService";
import MySwal from "sweetalert2";

export default function ProfilePage() {
    let authCtx = useContext(AuthenticationContext);
    let [user, setUser] = useState({
        Consultant:{}
    });
    let uService = new UserService();

    useEffect(() => {
        let connected = authCtx.getAuthenticationData();
        uService.getOne(connected.id).then(json => {
            if (json.success) {
                setUser(json.data)
            }else{
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: json.message,
                });
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        })
    }, [])
    return <Page>
        <Profile user={user}></Profile>
    </Page>;
}
