import SmsViewer from "../mini/SmsViewer";
import React, {useEffect, useState} from "react";
import ConsultantService from "../Services/ConsultantService";
import Loader from "../../tools/Loader";
import MySwal from "sweetalert2";
import Page from "./Page";
import SmsService from "../Services/SmsService";
import {sendIcon} from "../mini/SvgIcons";

export default function SMSPage() {
    let [consultants, setConsultants] = useState([]);
    let [selected, setSelected] = useState({})
    let service = new ConsultantService();
    let smsService = new SmsService();
    let [smsList, setSmsList] = useState([]);
    let [text, setText] = useState("");
    let autoSizeView;
    useEffect(() => {
        Loader.loadstart('chargement de la liste des consultants')
        service.getAll().then(json => {
            if (json.success) {
                setConsultants(json.data);
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: json.message,
                })
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        }).finally(e => {
            Loader.loadstop()
        })
    }, [])
    let loadConsultantSMS = (consultant) => {
        //console.log(consultant)
        smsService.getOne(consultant.id).then(json => {
            //console.log(json)
            setSmsList(json.data)
            setSelected(consultant)
        })

    }
    let getConsultantSideView = (consultant) => {
        return <li key={consultant.id} onClick={e => loadConsultantSMS(consultant)}>
            <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg" alt=""/>
            <div>
                <h2>{consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h2>
                <h3>
                    <span className="status"></span>
                    {consultant.Utilisateur.phone}
                </h3>
            </div>
        </li>
    }
    return (<Page>
        <div className="smsPage">
            <aside>
                <header>
                    <input type="text" placeholder="search"/>
                </header>
                <ul>
                    {consultants.map(consult => {
                        return getConsultantSideView(consult);
                    })}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_02.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_03.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status orange"></span>*/}
                    {/*            offline*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_04.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status green"></span>*/}
                    {/*            online*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_05.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status orange"></span>*/}
                    {/*            offline*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_06.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status green"></span>*/}
                    {/*            online*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_07.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status green"></span>*/}
                    {/*            online*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_08.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status green"></span>*/}
                    {/*            online*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_09.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status green"></span>*/}
                    {/*            online*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_10.jpg" alt=""/>*/}
                    {/*    <div>*/}
                    {/*        <h2>Prénom Nom</h2>*/}
                    {/*        <h3>*/}
                    {/*            <span className="status orange"></span>*/}
                    {/*            offline*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ul>
            </aside>
            <main>
                <header>
                    {selected.Utilisateur && <>
                        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1940306/chat_avatar_01.jpg" alt=""/>
                        <div>
                            <h2>Communication SMS
                                avec {selected.Utilisateur.firstname || "( Aucun consultant séléctionné )"} {selected.Utilisateur.lastname || ""}</h2>
                            <h3> {smsList.length} messages</h3>
                        </div>
                    </>}


                    {!selected.Utilisateur && <div>
                        <h2>( Aucun consultant séléctionné )</h2>
                        <h3>Veuillez selectionner un consultant dans la liste</h3>
                    </div>}


                </header>
                <SmsViewer smsList={smsList} consultant={selected} getAutosizeFn={fnAutoSize => {
                    autoSizeView = fnAutoSize;
                }}></SmsViewer>
                <footer>
                    <textarea disabled={selected.Utilisateur === undefined} placeholder="Type your message" value={text}
                              onChange={e => setText(e.target.value)}></textarea>
                    <a className="bg-blue-500 hover:bg-blue-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        onClick={e => {
                        e.preventDefault();
                        smsService.sendSms(selected, text).then(json => {
                            if (json.success) {
                                let l = [...smsList];
                                l.push(json.data);
                                setSmsList(l);
                                autoSizeView && autoSizeView()
                            } else {
                                MySwal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: json.message,
                                })
                            }


                        })
                    }}>{sendIcon}</a>
                </footer>
            </main>
        </div>
    </Page>);

}