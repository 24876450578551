import {deleteIcon, editIcon, infoIcon} from "../SvgIcons";
import React, {useContext} from "react";
import AuthenticationContext from "../../secu/AuthenticationContext";

export default function MissionRow(props) {
    let person = props.mission;
    const authContext = useContext(AuthenticationContext);

    return <tr onDoubleClick={e => {
        e.preventDefault();
        if (props.onEdit && typeof props.onEdit === "function") {
            props.onEdit(person)
        }
    }}>
        <td className="px-6 py-2 whitespace-nowrap">
            <div className="flex items-center">
                <div className="">
                    <div
                        className="text-sm font-medium text-gray-900"> {person.Client.denominationSocial}</div>

                </div>
            </div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap  ">
            <div
                className="text-sm text-gray-900">{person.Consultant.Utilisateur.firstname} {person.Consultant.Utilisateur.lastname} </div>

        </td>
        <td className="px-6 py-2 whitespace-nowrap  ">
            <div
                className="text-sm text-gray-900">{person.Employeur.denominationSocial}</div>
            <div
                className="text-sm text-gray-500">{person.Client.zipCode} {person.Client.city}</div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap ">
            <div className="text-sm  text-gray-900">Du
                : {person.dateStart ? new Date(person.dateStart).toLocaleDateString("fr-FR") : "Aucune date de debut"}</div>
            <div
                className="text-sm  text-gray-900">au
                : {person.dateEnd ? new Date(person.dateEnd).toLocaleDateString("fr-FR") : "Aucune date de fin"}</div>
        </td>


        <td className="px-6 py-2 whitespace-nowrap w-1">
            {person.dateEnd == null && <span
                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                Active
                                            </span>}
            {person.dateEnd != null && <span
                className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-pink-100 text-pink-800">
                                                closed
                                            </span>}
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-right text-sm font-medium w-1">
            <button
                className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={"Editer la mission"}
                onClick={e => {
                    e.preventDefault();
                    if (props.onEdit && typeof props.onEdit === "function") {
                        props.onEdit(person)
                    }
                }}>
                {editIcon}
            </button>
            <button
                className="bg-red-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={"Supprimer la mission"}
                onClick={e => {
                    e.preventDefault();
                    if (props.onDelete && typeof props.onDelete === "function") {
                        props.onDelete(person)
                    }
                }}>
                {deleteIcon}
            </button>
            {authContext.isAdmin()&&<button
                title={"Visiualiser le CRA"}
                className="bg-gray-400 active:bg-gray-400 uppercase text-white font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={e => {
                    e.preventDefault();
                    if (props.OnHisto && typeof props.OnHisto === "function") {
                        props.OnHisto(person)
                    }
                }}>
                {infoIcon}
            </button>}
        </td>
    </tr>;
}