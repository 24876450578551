import saveAs from '../../tools/saveAs';
import Service from './Service';
import AuthManager from "../secu/AuthManager";
class ConsultantService extends Service {
    constructor() {
        super("/api/consultant");
    }
    previewDoc(doc) {

        let fileDownloadURL = this.url + "/docs1/download/" + doc.id;
        window.open(fileDownloadURL, "_black")

    }

    downloadDocs1(doc) {
        return new Promise((resolve, reject) => {
            let fileDownloadURL = this.url + "/docs1/download/" + doc.id;
            var xhr = new XMLHttpRequest();
            xhr.open('GET', fileDownloadURL, true);
            xhr.setRequestHeader("Authorization", "bearer " + localStorage.getItem("token"));
            xhr.responseType = 'blob';
            xhr.onload = function (e) {
                let header = xhr.getResponseHeader('Content-Disposition');
                let filename = header.split('=')[1];
                saveAs(xhr.response, filename);
                resolve()
            };
            xhr.onerror = function (e) {
                reject(e);
                //Loader.loadstop();
            };
            xhr.send();

            // fetch(this.url + "/docs1/download/"+doc.id, {
            //     method: "GET",
            //     headers: {
            //         "Authorization": "bearer " + localStorage.getItem("token")
            //     }
            // })
            //     .then(response => {
            //         if (response.ok) {
            //             return response.json();
            //         } else if (response.status == 401) {
            //             AuthManager.desAuthenticateUser();
            //             window.location.href = '/login';
            //         }

            //     }).then(jsonresponse => { resolve(jsonresponse); })
            //     .catch(err => { reject(err); });
        });
    }

    readDocs1(id) {
        return new Promise((resolve, reject) => {
            fetch(this.url + "/docs1/" + id, {
                method: "GET",
                headers: {
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }
    sendDocs1(formData) {
        return new Promise((resolve, reject) => {
            fetch(this.url + "/docs1", {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }



    getCaData(id) {
        return fetch(this.url+'/cra/'+id, {
            method: "GET",
            headers: {
                "content-type": 'application/json',
                "Authorization": "bearer " + localStorage.getItem("token")
            }
        }).then(this.handleResponse).catch(error => {
            if (error.status == 401) {
                AuthManager.desAuthenticateUser();
                window.location.href = '/login';
            }
        });


    }


}
export default ConsultantService;
