import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "./Page";
import ClientService from "../Services/ClientService";
import ClientGrid from "../mini/ClientGrid";
import MySwal from "sweetalert2";
import Loader from "../../tools/Loader";
import {addIcon} from "../mini/SvgIcons";


export default function Clients() {
    const navigate = useNavigate();
    let [clients, setClients] = useState([]);
    let service = new ClientService();
    useEffect(() => {
        Loader.loadstart('chargement de la liste des clients')
        service.getAll().then(json => {
            if (json.success) {
                setClients(json.data);
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        }).finally(()=>{
            Loader.loadstop();
        })
    }, [])
    let deleteClient = (client) => {
        MySwal.fire({
            title: `Suppression de ${client.denominationSocial} ?`,
            text: "Êtes-vous sûr de vouloir  supprimer définitivement le client!",
            // type: "question",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Oui, je supprime !",
            cancelButtonText: "Non, je ne supprime pas !",
            // closeOnConfirm: false
        }).then((res) => {
            if (res.isConfirmed) {
                Loader.loadstart(`Suppression de ${client.denominationSocial} `)

                service.delete(client.id, client).then(json => {
                    if (json.success) {
                        let e = clients
                        e = e.filter((em) => {
                            return em.id != client.id
                        })
                        setClients(e)
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                }).finally(()=>{
                    Loader.loadstop();
                });
            }
        })
    }
    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                data-cy={"New-Client-Btn"}
                className="bg-green-500  hover:bg-green-700 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={"Créer un nouveau client"}
                onClick={() => {
                    navigate('/clients/create')
                }}> {addIcon}
            </button>
        </div>
        <ClientGrid
            onEdit={e => {
                navigate('/clients/create/' + e.id)
            }}
            onDelete={deleteClient}
            clients={clients}></ClientGrid>
    </Page>;
}
