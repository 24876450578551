/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment, useContext, useEffect, useState} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {BellIcon, MenuIcon, XIcon} from '@webologie/react-icons/outline'
import {Link, useNavigate} from "react-router-dom";
import MeService from "../Services/MeService";
import AuthenticationContext from "../secu/AuthenticationContext";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function AdminNavBar(props) {
    const authContext = useContext(AuthenticationContext);
    let navItems = [
        // { name: 'Tableau de board', href: '/', current: true, show: true},
        {name: 'Consultants', href: '/consultant', current: true, show: true, dataCy: "menu-consultant"},
        {
            name: 'Employeurs',
            href: '/employeurs',
            current: false,
            show: authContext.isSuperAdmin(),
            dataCy: "menu-employeur"
        },
        {name: 'Clients', href: '/clients', current: false, show: true, dataCy: "menu-client"},
        {name: 'Missions', href: '/missions', current: false, show: true, dataCy: "menu-missions"},
        {
            name: 'Administrateurs',
            href: '/admins',
            current: false,
            show: authContext.isSuperAdmin(),
            dataCy: "menu-administrateur"
        },      {
            name: 'SMS',
            href: '/sms',
            current: false,
            show: authContext.isAdmin(),
            dataCy: "menu-sms"
        },
    ]


    let navigate = useNavigate();
    let [src, setSrc] = useState("/img/profile.jpg");
    let service = new MeService()
    useEffect(() => {
        if (authContext.isConnected()) {
            service.getProfilePhoto().then(blob => {
                if (blob) {
                    var urlCreator = window.URL || window.webkitURL;
                    var imageUrl = urlCreator.createObjectURL(blob);
                    setSrc(imageUrl);
                }
            })
        }

    }, [])
    return (
        <Disclosure as="nav" className="shadow-md">
            {({open}) => (
                <>
                    <div className="max-w-12xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div
                                className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <Link to={"/"}>
                                        <img src="/img/milogo.png" style={{height: "40px", marginTop: "-3px"}} alt=""/>
                                    </Link>
                                </div>
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4">
                                        {navItems.map((item) => {
                                            if (item.show) {
                                                item.current = window.location.pathname === item.href;
                                                //item.current:window.location.pathname.match(new RegExp(item.href.replace("/","")));
                                                return <Link
                                                    data-cy={item.dataCy}
                                                    key={item.name}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current ? 'bg-blue-200 text-blue-800' : 'text-gray-500 hover:text-blue-600 hover:bg-blue-100',
                                                        'px-3 py-2 rounded-md text-sm font-medium'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </Link>;
                                            } else {
                                                return null
                                            }

                                        })}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                <button
                                    type="button"
                                    className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                >
                                    <span className="sr-only">View notifications</span>
                                    <BellIcon className="h-6 w-6" aria-hidden="true"/>
                                </button>

                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative z-10">
                                    <div>
                                        <Menu.Button
                                            className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            <img
                                                id={"Profile_Pic"}
                                                className="h-8 w-8 rounded-full"
                                                src={src}
                                                alt=""
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({active}) => (
                                                    <Link
                                                        to="/profile"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Your Profile
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                            {authContext.isSuperAdmin() && <Menu.Item>
                                                {({active}) => (
                                                    <Link
                                                        to="/settings"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Settings
                                                    </Link>
                                                )}
                                            </Menu.Item>}
                                            {authContext.isSuperAdmin() && <Menu.Item>
                                                {({active}) => (
                                                    <Link
                                                        to="/actions"
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Actions/logs
                                                    </Link>
                                                )}
                                            </Menu.Item>}
                                            <Menu.Item>
                                                {({active}) => (
                                                    <a
                                                        data-cy={"disconnect"}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            MySwal.fire({
                                                                title: `Déconnexion ?`,
                                                                text: "Êtes-vous sûr de vouloir vous déconnecter ?",
                                                                type: "error",
                                                                showCancelButton: true,
                                                                confirmButtonColor: "#DD6B55",

                                                                confirmButtonText: "Oui, je me déconnecte !",
                                                                cancelButtonText: "Non, je reste connecté !",
                                                                closeOnConfirm: false
                                                            }).then((res) => {
                                                                if (res.isConfirmed) {
                                                                    authContext.logout();
                                                                    navigate("/login");
                                                                    props.onLogedOut && props.onLogedOut(e)
                                                                }
                                                            })


                                                            // if (confirm("Êtes-vous sûr de vouloir vous déconnecter ?")) {
                                                            //     authContext.logout();
                                                            //     navigate("/login");
                                                            //     props.onLogedOut && props.onLogedOut(e)
                                                            // }
                                                        }}
                                                        className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                    >
                                                        Déconnexion
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navItems.map((item) => {
                                    if (item.show) {
                                        return <Link
                                            key={item.name}
                                            to={item.href}
                                            data-cy={item.dataCy}

                                            className={classNames(
                                                item.current ? 'bg-blue-200 text-blue-800' : 'text-gray-500 hover:text-blue-600 hover:bg-blue-100',
                                                'block px-3 py-2 rounded-md text-base font-medium'
                                            )}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Link>
                                    }
                                }
                            )}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )

}

export default AdminNavBar;
