import React from 'react'
import ConsultantRow from './units/ConsultantRow';


export default function ActionGrid(props) {

    return (
        <div className="flex flex-col w-full">
            <div className="w-full">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="min-w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Date
                                </th>

                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    type
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    text
                                </th>

                            </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {props.actions.map((action) => {
                                return <tr className='cursor-pointer hover:bg-gray-200' key={action.id}>
                                    <td className="px-6 py-4 whitespace-nowrap  text-sm font-medium w-1">
                                        <div className="flex items-center">
                                            <div
                                                className="text-sm font-medium text-gray-900"> {(new Date(action.createdAt)).toLocaleString()}</div>
                                        </div>
                                    </td>


                                    <td className="px-6 py-4 whitespace-nowrap  text-sm font-medium w-1">
                                        <div className="flex items-center">
                                            {action.type === "creation" && <span
                                                className="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs">{action.type}</span>}
                                            {action.type === "mise a jour" && <span
                                                className="bg-blue-200 text-blue-600 py-1 px-3 rounded-full text-xs">{action.type}</span>}
                                            {action.type === "suppression" && <span
                                                className="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs">{action.type}</span>}
                                            {action.type.indexOf("CRON") !== -1 && <span
                                                className="bg-indigo-200 text-indigo-600 py-1 px-3 rounded-full text-xs">{action.type}</span>}
                                        </div>
                                    </td>


                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">

                                            <div className="text-sm font-medium text-gray-900"
                                                 dangerouslySetInnerHTML={{__html: action.text}}/>

                                        </div>
                                    </td>

                                </tr>
                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

ActionGrid.defaultProps = {
    consultants: [],
    label: "Button Text"
};
