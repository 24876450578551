import React, { useState } from "react"



export default function ClientSelector(props) {

    let [choicesVisible, setChoicesVisible] = useState(false)
   // console.log("props.client", props.client);
    let [selected, setSelected] = useState(props.client)
    let [filter, setFilter] = useState(props.client ? props.client.denominationSocial : "")
    let [clients, setClients] = useState([...props.clients])
    if (!selected && props.client) {
        setSelected(props.client);
        setFilter(props.client.denominationSocial);

    }

    let getOption = () => {
        if (choicesVisible) {
            return <ul className="w-full mt-1 absolute pr-3 ease-linear transition-all duration-150 z-10">
                {clients.map((client, i) => {
                    return <li key={i} className="w-full bg-gray-200 hover:bg-gray-300 p-3  border border-gray-400 rounded" onClick={(e) => {
                        e.stopPropagation();
                        setChoicesVisible(false);
                        setSelected(client);
                        props.onselect && props.onselect(client);
                        setFilter(client.denominationSocial);
                        filterByText(client.denominationSocial)
                    }}>{client.denominationSocial}</li>;
                })}
            </ul>;
        }
    }

    let filterByText = (aaa) => {
        var PATTERN = new RegExp(`${aaa.toUpperCase()}`),
            filtered = props.clients.filter(function (str) { return PATTERN.test(str.denominationSocial.toUpperCase()); });
        setClients(filtered)
    }
    //console.log("selected", selected)
    if (selected) {
        return <div className="w-full mb-3 px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold pb-3"
                htmlFor="grid-first-name">
                Client :
            </label>
            <div className="flex w-full items-center ">
                <div className="flex-1 mr-3">

                    <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                            <tr className="border border-gray-200">
                                <td className="px-6  py-2.5 whitespace-nowrap">
                                    {selected.denominationSocial}
                                </td>
                                <td className="px-6 py-2.5 whitespace-nowrap">
                                    {selected.contact} - {selected.contactPhone}
                                </td>
                                <td className="px-6  py-2.5 whitespace-nowrap">
                                    {selected.city}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button
                    className={(props.ro && "bg-gray-400 cursor-not-allowed " || " bg-green-500 hover:bg-green-700")+" uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"}
                    type="button"
                    style={{ height: "46px" }}
                    disabled={props.ro}
                    onClick={e => {
                        e.preventDefault();
                        props.onclear && props.onclear();
                        setSelected(null);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                </button>
            </div>
        </div>




    }

    return <div className="w-full flex items-center mb-3 px-3">

        <div className="flex-1 pr-3 relative">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold pb-3"
                htmlFor="grid-first-name">
                Client :
            </label>
            <input
                autoFocus={props.autoFocus || false}
                autoComplete={"off"}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white "
                id="grid-first-name"
                required={true}
                onFocus={e => {
                    if (e.target.value.length > 1) {
                        setChoicesVisible(true)
                    }
                }}
                value={filter}
                // onBlur={e => {
                //     if (e.target.value.length > 1) {
                //         setChoicesVisible(false)
                //     }
                // }}
                // value={consultant.firstname || ""}
                onChange={e => {
                    if (e.target.value.length > 1) {
                        filterByText(e.target.value)
                        setChoicesVisible(true)
                    } else {
                        setChoicesVisible(false)

                    }
                    setFilter(e.target.value)

                }}
                type="text"
                placeholder="ex: Dupont" />
            {getOption()}
            <p className="text-gray-500 text-xs italic">Selectionner un client existant, ou Ajoutez un Nouveau.</p>
        </div>
        <button
            disabled={props.ro}
            style={{ height: "46px" }}
            className="bg-green-500  mt-3 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-3 rounded outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
            onClick={props.onaddNewClient || (() => { })}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
            </svg>
        </button>
    </div >;


}

ClientSelector.defaultProps = {
    clients: [
        "sdfjkhg"
    ]
}