
import React from "react";
import { Route, Routes ,BrowserRouter} from "react-router-dom";
import routes from "./routes";


function App() {

    return (
        <BrowserRouter>
            <div className={"h-full"}>
                <Routes>
                    {routes.map((route, i) => {
                        return <Route path={route.path} element={<route.component/>} key={i}></Route>
                    })}
                </Routes>
            </div>
        </BrowserRouter>


    );
}

export default App;
