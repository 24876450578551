import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import ClientService from "../Services/ClientService";
import Field from "../forms/mini/Field";
import MySwal from "sweetalert2";
import {cancelIcon, validateIcon} from "../mini/SvgIcons";


export default function ClientForm(props) {
    let service = new ClientService();
    let params = useParams();
    useEffect(() => {
        if (params && params.id) {
            service.getOne(params.id).then(json => {
                if (json.success) {
                    setClient(json.data);
                }
            })
        }
    }, [])
    let denominationSocialF, rcsF, siretF, presidentF, tvaIntracomF, contactF, contactPhoneF, contactMailF, addressF,
        zipcodeF, cityF;
    let isFormValid = () => {
        return denominationSocialF && denominationSocialF.isValid() &&
            rcsF && rcsF.isValid() &&
            siretF && siretF.isValid() &&
            presidentF && presidentF.isValid() &&
            tvaIntracomF && tvaIntracomF.isValid() &&
            contactF && contactF.isValid() &&
            contactPhoneF && contactPhoneF.isValid() &&
            contactMailF && contactMailF.isValid() &&
            addressF && addressF.isValid() &&
            zipcodeF && zipcodeF.isValid() &&
            cityF && cityF.isValid();
    }
    let submitForm = function () {
        if (isFormValid()) {
            if (params && params.id) {
                service.update(client).then(json => {
                    if (json.success) {
                        //console.log(json)
                        navigate("/clients");
                    } else {

                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })

                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                });
            } else {
                fetch("/api/client", {
                    method: "POST",
                    body: JSON.stringify(client),
                    headers: {
                        "content-type": 'application/json',
                        "Authorization": "bearer " + localStorage.getItem("token")
                    }
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else if (response.status == 401) {
                            AuthManager.desAuthenticateUser();
                            window.location.href = '/login';
                        }

                    })
                    .then(json => {
                        if (json.success) {
                            navigate("/clients");
                        } else {
                            MySwal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: json.message,
                            })
                        }
                    })
                    .catch(err => {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        })
                    });
            }
        }


    }
    const navigate = useNavigate();
    let [client, setClient] = useState({});

    return <div className={"grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200"}>
        <div role="Form" className={"col-span-2"}>
            <form className="flex flex-col justify-center p-10">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <Field
                            label={"Dénomination sociale"}
                            data-cy="Client-denominationSocial"
                            ref={(r) => {
                                denominationSocialF = r;
                            }}
                            value={client.denominationSocial || ""}
                            required={true}
                            min={3}
                            max={40}
                            onChange={e => {
                                let em = {...client};
                                em.denominationSocial = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-denomination-sociale"
                            type="text"
                            placeholder="ex: Citeo"/>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"RCS"}
                            data-cy="Client-rcs"
                            ref={(r) => {
                                rcsF = r;
                            }}

                            value={client.rcs || ""}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.rcs = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: Ville 000 000 000"/>
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"Siret"}
                            data-cy="Client-siret"
                            ref={(r) => {
                                siretF = r;
                            }}
                            vtype={"num"}
                            vtypeMsg={"Doit être un numero de 14 chiffres"}
                            max={14}
                            value={client.siret || ""}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.siret = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: 84074430400022"/>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"Président"}
                            data-cy="Client-president"
                            ref={(r) => {
                                presidentF = r;
                            }}
                            min={3}
                            max={40}
                            value={client.president || ""}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.president = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: jean mouloud de montalambere"/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"TVA intercom."}
                            data-cy="Client-tvaIntracom"
                            ref={(r) => {
                                tvaIntracomF = r;
                            }}
                            value={client.tvaIntracom || ""}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.tvaIntracom = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: FR0000000000"/>
                    </div>


                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"contact"}
                            data-cy="Client-contact"
                            ref={(r) => {
                                contactF = r;
                            }}
                            min={3}
                            max={40}
                            value={client.contact || ""}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.contact = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: moha begdadi"/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"contact phone"}
                            data-cy="Client-contact-phone"
                            ref={(r) => {
                                contactPhoneF = r;
                            }}
                            vtype="phone"
                            value={client.contactPhone || ""}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.contactPhone = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}

                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: 0600000000"/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"contact email"}
                            data-cy="Client-contact-email"
                            ref={(r) => {
                                contactMailF = r;
                            }}
                            value={client.contactMail || ""}
                            vtype="email"
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.contactMail = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: user@exaple.com"/>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"address"}
                            data-cy="Client-address"
                            value={client.address || ""}
                            ref={(r) => {
                                addressF = r;
                            }}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.address = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex:  12 rue de la reine Amelie"/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"code postal"}
                            data-cy="Client-zipCode"
                            ref={(r) => {
                                zipcodeF = r;
                            }}
                            value={client.zipCode || ""}
                            required={true}
                            vtype="codePost"
                            vtypeMsg={"Doit être un code postal valid*"}
                            onChange={e => {
                                let em = {...client};
                                em.zipCode = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: 94000"/>

                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"ville"}
                            data-cy="Client-ville"
                            ref={(r) => {
                                cityF = r;
                            }}
                            value={client.city || ""}
                            required={true}
                            onChange={e => {
                                let em = {...client};
                                em.city = e.target.value;
                                setClient(em);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name"
                            type="text"
                            placeholder="ex: Bobiny"/>
                    </div>
                </div>
                <div className=" flex justify-between">
                    <button
                        className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none  mb-1 ease-linear transition-all duration-150"
                        type="button"
                        title={"Annuler"}
                        onClick={() => {
                            navigate('/clients')
                        }}>
                        {cancelIcon}
                    </button>
                    <button
                        data-cy="SaveClient"
                        className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none  mb-1 ease-linear transition-all duration-150"
                        type="button"
                        title={"Valider"}
                        onClick={e => {
                            submitForm();
                            //console.log(client);
                        }}>
                        {validateIcon}
                    </button>
                </div>
            </form>

        </div>
    </div>
}
