import React, { useContext, useEffect, useState } from "react";
// import AuthManager from "../secu/AuthManager";
import MeService from "../Services/MeService";
// import EmailModal from "./EmailModal";
// import PasswordlModal from "./PasswordlModal";
import AuthenticationContext from "../secu/AuthenticationContext";
import ProfileConsultantPart from "./ProfileConsultantPart";
import PasswordlModal from "./PasswordlModal";
import MySwal from "sweetalert2";

export default function Profile(props) {
    let [src, setSrc] = useState("/img/profile.jpg");

    let authCtx = useContext(AuthenticationContext);

    // InDbAuth.getAuthenticationData(connectedUser=>{
    //     //let connectedUser = event.target.result[0];
    //     setUser(connectedUser);
    // })

    let service = new MeService()
    let setPassModalOpen;
    let openFileChoser = function () {
        var x = document.createElement("input");
        x.setAttribute("type", "file");
        x.style.display = "none";
        x.accept = "image/*";
        document.body.appendChild(x);
        x.addEventListener('change', (e) => {
            // send photo to the server
            service.uploadProfilePhoto(x.files[0]).then(result => {
                var urlCreator = window.URL || window.webkitURL;
                var imageUrl = urlCreator.createObjectURL(x.files[0]);
                setSrc(imageUrl);
                document.querySelector("#Profile_Pic").src = imageUrl;
            })
            x.parentNode.removeChild(x);
        });
        x.click();
    }

    useEffect(() => {
        service.getProfilePhoto().then(blob => {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(blob);
            setSrc(imageUrl);
        })
    }, [])
    return (
        <>
            {/* <EmailModal
                onValidate={(e, closeFn, email) => {
                    service.update({ email }).then(res => {
                        AuthManager.setAuthenticationEmail(email)
                        setUser(AuthManager.getAuthenticationData())
                        closeFn(false)
                    });
                }}
                value={props.user.login}
                getTools={({ setOpen }) => {
                     setModalOpen = setOpen;
                }}></EmailModal> */}
            <PasswordlModal
                onValidate={(e, closeFn, data) => {
                    service.update(data).then(res => {
                        if(res.success){
                            closeFn(false);
                        }else{
                            MySwal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: res.message,
                            })
                        }

                    }).catch(err=>{
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        })
                    })
                }}
                getTools={({ setOpen }) => {
                    setPassModalOpen = setOpen;
                }}/>
            <main className="profile-page relative mt-5">
                <section className="relative block h-500-px">
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                    </div>
                </section>
                <section className="relative bg-blueGray-200">
                    <div className="container mx-auto px-4">
                        <div
                            className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
                            <div className="px-6">
                                <div className="flex flex-wrap justify-between">

                                    <div className="w-full lg:w-4/12 px-4 order-2 text-right flex flex-col justify-center">
                                         {/* <div className="pt-6 px-3">
                                            <button
                                                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={e => {
                                                    openFileChoser()
                                                }}
                                            >
                                                Modifier ma photo
                                            </button>
                                        </div> */}
                                        {/* <div className="pt-1 px-3 ">
                                            <button
                                                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={e => setModalOpen(true)}
                                            >
                                                Modifier mon email
                                            </button>
                                        </div> */}
                                        <div className="pt-1 px-3">
                                            <button
                                                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                                onClick={e => setPassModalOpen(true)}
                                            >
                                                Modifier mon mot de passe
                                            </button>
                                        </div> 
                                    </div>
                                    <div className="w-full lg:w-3/12 px-4 order-1">
                                        <h3 className="text-xl font-semibold leading-normal text-blueGray-700">
                                            <div className="flex justify-start items-center flex-col">
                                                <div
                                                onClick={openFileChoser}
                                                    style={{ backgroundImage: "url(" + src + ")" }}
                                                    alt="Profile photo"
                                                    src={src}
                                                    className="shadow-xl rounded-full w-20 h-20 align-middle border-none bg-cover bg-center cursor-pointer"
                                                />
                                                <div className="text-sm font-semibold leading-normal text-blueGray-700 text-center">{props.user.firstname} {props.user.lastname}</div>
                                                <div className="text-base font-semibold leading-normal text-blueGray-700 text-center">{props.user.phone}</div>
                                                <div className="text-lg font-semibold leading-normal text-blueGray-700 text-center">{props.user.login}</div>

                                                
                                            </div>
                                        </h3>

                                    </div>
                                </div>
                                {/* <div className="text-center mt-12">
                                    <div className="w-full flex justify-center items-center">
                                        <div
                                            style={{ backgroundImage: "url(" + src + ")" }}
                                            alt="Profile photo"
                                            src={src}
                                            className="shadow-xl rounded-full w-60 h-60 align-middle border-none bg-cover bg-center"
                                        />
                                    </div>
                                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                        {user.login}
                                    </h3>
                                    <div
                                        className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                                        <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                                        Paris, France
                                    </div>
                                    <div className="mb-2 text-blueGray-600 mt-10">
                                        <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                                        Solution Manager - ayoub le magnifique
                                    </div>
                                    <div className="mb-2 text-blueGray-600">
                                        <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
                                        University of Computer Science
                                    </div>
                                </div> */}
                                <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-full lg:w-9/12 px-4">
                                            {authCtx.isConsultant() && <ProfileConsultantPart {...props}></ProfileConsultantPart>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    );
}
