import React, {useState} from 'react'
import {addMissionIcon, deleteIcon, editIcon, smsIcon} from "../SvgIcons";

export default function ConsultantRow(props) {
    let [expanded, setExpanded] = useState(false);


    let createUrlFromBlob = (blob) => {
        if (blob == null) {
            return "/img/team-2-800x800.jpg"
        } else {
            // return "data:image/jpeg;base64,"+blob.data;
            var b = new Blob([new Uint8Array(blob.data)], blob);
            var urlCreator = window.URL || window.webkitURL;
            return urlCreator.createObjectURL(b);
        }
    };


    let getChevron = () => {
        if (props.Consultant.Missions.length > 1) {
            if (expanded) {
                return <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"/>
                </svg>;
            } else {

                return <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"/>
                </svg>;
            }
        }

    };
    let getExpanded = () => {
        if (props.Consultant.Missions.length > 1) {
            if (expanded) {
                return (props.Consultant.Missions.map((mission) => {
                    return <tr key={mission.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                </div>
                                <div className="ml-4">
                                    {/* <div
                className="text-sm font-medium text-gray-900"> {person.firstname} {person.lastname}</div>
            <div className="text-sm text-gray-500">{person.login}</div>
            <div className="text-sm text-gray-500">{person.phone}</div> */}

                                </div>
                            </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap ">
                            {mission.Client.denominationSocial}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap ">
                            {mission.Employeur.denominationSocial}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap ">
                            {(mission.PrevHistories && mission.PrevHistories.length > 0 && mission.PrevHistories[0].nbJours != null) ? mission.PrevHistories[0].nbJours + " Jours" : "Prev non saisie"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap ">

                            {(mission.CraHistories && mission.CraHistories.length > 0 && mission.CraHistories[0].craFilename) ? mission.CraHistories[0].craFilename : "Cra non saisie"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 w-1">{mission.title}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-1">
                            {/* <button
                                className="bg-gray-500 hover:bg-green-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={e => {
                                    e.preventDefault();
                                    if (props.onmission && typeof props.onmission === "function") {
                                        props.onmission(person)
                                    }
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z" clipRule="evenodd" />
                                </svg>
                            </button> */}
                            {/* <button
        className="bg-green-500 hover:bg-green-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={e => {
            e.preventDefault();
            if (props.onEdit && typeof props.onEdit === "function") {
                props.onEdit(person)
            }
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>
    </button> */}
                            {/* <button
        className="bg-red-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={e => {
            e.preventDefault();
            if (props.onDelete && typeof props.onDelete === "function") {
                props.onDelete(person)
            }
        }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
    </button> */}
                            {/* <button
                                className="bg-blue-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={e => {
                                    e.preventDefault();
                                    if (props.onMailSend && typeof props.onMailSend === "function") {
                                        props.onMailSend(person)
                                    }
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                            </button> */}
                        </td>
                    </tr>;


                }));
            }
        }

    };
    let {Utilisateur} = props.Consultant;
    return (<>
        <tr className='cursor-pointer hover:bg-gray-200' key={props.Consultant.id}
            onClick={() => {
                setExpanded(!expanded);
            }}
            onDoubleClick={() => {
                props.onEdit(props.Consultant)
            }}>
            <td className="px-6 py-2 whitespace-nowrap ">
                <div className="flex items-center">
                    <div className="flex-shrink-0">
                        {getChevron()}
                    </div>
                    {/* <div className="flex-shrink-0 h-10 w-10">
                    <img className="h-10 w-10 rounded-full"
                        src={createUrlFromBlob(person.pic)} alt="" />
                </div> */}
                    <div className="ml-4 ">
                        <div
                            className="text-sm font-medium text-gray-900"> {Utilisateur.firstname} {Utilisateur.lastname}</div>
                        <div className="text-sm text-gray-500">{Utilisateur.login}</div>
                        <div className="text-sm text-gray-500">{Utilisateur.phone}</div>

                    </div>
                </div>
            </td>
            {/* <td className="px-6 py-4 whitespace-nowrap">

            <div className="text-sm  text-gray-900">{props.Consultant.address}</div>
            <div
                className="text-sm  text-gray-900">{props.Consultant.zipCode} {props.Consultant.city}</div>
        </td> */}
            <td className="px-6 py-2 whitespace-nowrap ">
                {props.Consultant.Missions && props.Consultant.Missions.length && props.Consultant.Missions.length != 1 && "Multi Clients" || (props.Consultant.Missions.length == 1 && props.Consultant.Missions[0].dateEnd == null ? props.Consultant.Missions[0].Client.denominationSocial : "PAS DE MISSIONS")}

            </td>
            <td className="px-6 py-2 whitespace-nowrap ">
                {props.Consultant.Missions && props.Consultant.Missions.length && props.Consultant.Missions.length != 1 && "Multi Employeurs" || (props.Consultant.Missions.length == 1 && props.Consultant.Missions[0].dateEnd == null ? props.Consultant.Missions[0].Employeur.denominationSocial : "PAS DE MISSIONS")}
            </td>
            <td className="px-6 py-2 whitespace-nowrap ">
                {(props.Consultant.Missions && props.Consultant.Missions.length == 1 && props.Consultant.Missions[0].PrevHistories.length == 1 && props.Consultant.Missions[0].dateEnd == null && props.Consultant.Missions[0].PrevHistories[0].nbJours !== null) ? props.Consultant.Missions[0].PrevHistories[0].nbJours + " jours" : "Prev non saisie"}
            </td>
            <td className="px-6 py-2 whitespace-nowrap ">
                {(props.Consultant.Missions && props.Consultant.Missions.length == 1 && props.Consultant.Missions[0].CraHistories.length == 1 && props.Consultant.Missions[0].dateEnd == null && props.Consultant.Missions[0].CraHistories[0].craFilename) ? props.Consultant.Missions[0].CraHistories[0].craFilename : "Cra non saisie"}
            </td>
            <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-500 w-1">{props.Consultant.Missions && props.Consultant.Missions.length && props.Consultant.Missions.length != 1 && props.Consultant.Missions.length + " Missions" || (props.Consultant.Missions.length == 1 ? props.Consultant.Missions[0].title : "PAS DE MISSIONS")}</td>
            <td className="px-6 py-2 whitespace-nowrap text-right text-sm font-medium w-1">
                <button
                    className="bg-green-500 hover:bg-green-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    title={`Créer une mission pour  le consultant : ${Utilisateur.firstname} ${Utilisateur.lastname}`}
                    onClick={e => {
                        e.preventDefault();
                        if (props.onmission && typeof props.onmission === "function") {
                            props.onmission(props.Consultant)
                        }
                    }}
                >
                    {addMissionIcon}
                    {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">*/}
                    {/*    <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V8z" clipRule="evenodd" />*/}
                    {/*</svg>*/}
                </button>
                <button
                    className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    title={`Editer le consultant : ${Utilisateur.firstname} ${Utilisateur.lastname}`}
                    onClick={e => {
                        e.preventDefault();
                        if (props.onEdit && typeof props.onEdit === "function") {
                            props.onEdit(props.Consultant)
                        }
                    }}
                >
                    {editIcon}
                </button>
                <button
                    className="bg-red-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    title={`Supprimer le consultant : ${Utilisateur.firstname} ${Utilisateur.lastname}`}
                    onClick={e => {
                        e.preventDefault();
                        if (props.onDelete && typeof props.onDelete === "function") {
                            props.onDelete(props.Consultant)
                        }
                    }}
                >
                    {deleteIcon}
                </button>
                <button
                    className="bg-indigo-500 hover:bg-indigo-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    title={`Afficher les SMS du consultant : ${Utilisateur.firstname} ${Utilisateur.lastname}`}
                    onClick={e => {
                        e.preventDefault();
                        if (props.onMailSend && typeof props.onMailSend === "function") {
                            props.onMailSend(props.Consultant)
                        }
                    }}
                >
                    {smsIcon}
                </button>
            </td>
        </tr>
        {getExpanded()}
    </>);


}
