import React from 'react';
import AuthenticationContext from "./AuthenticationContext";
import {
    Navigate,
  } from "react-router-dom";
export default (BaseComponent, path) => {
    class RequireSuperAdmin extends React.Component {
        render() {
           return <AuthenticationContext.Consumer>
                {value =>{
                    if(value.isSuperAdmin()){
                    return (<BaseComponent {...this.props} />);
                    }else {
                        console.log(this.props)
                        //this.props.history.push(path || '/login');
                        return <Navigate to="/" replace />;                    }
                }}
            </AuthenticationContext.Consumer>
        }
    }
    return RequireSuperAdmin;
};
