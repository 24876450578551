import React from "react";
import Field from "../forms/mini/Field";


export default function ProfileConsultantPart(props) {

    // console.log(props.user);

    return (
        <>

            <main className="profile-page relative mt-5">
                <section className="relative block h-500-px">
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{transform: "translateZ(0)"}}
                    >
                    </div>
                </section>
                <section className="relative bg-blueGray-200">
                    <div className="container mx-auto px-4">
                        <div
                            className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg ">
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 md:w-1/2">
                                    {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                    {/*        htmlFor="grid-email">*/}
                                    {/*        Nom*/}
                                    {/*    </label>*/}
                                    <Field label={"Nom"}
                                           disabled={true}
                                           value={props.user.firstname}></Field>
                                </div>
                                <div className="w-full px-3 md:w-1/2">
                                    {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                    {/*        htmlFor="grid-email">*/}
                                    {/*        Prénom*/}
                                    {/*    </label>*/}
                                    <Field label={"Prénom"} disabled={true}
                                           value={props.user.lastname}></Field>
                                </div>
                            </div>


                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 md:w-1/2">
                                    {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                    {/*        htmlFor="grid-email">*/}
                                    {/*        Numéro de téléphone*/}
                                    {/*    </label>*/}
                                    <Field label={"Numéro de téléphone"}
                                           disabled={true}
                                           value={props.user.phone}></Field>
                                </div>
                                <div className="w-full px-3 md:w-1/2">
                                    {/*<label*/}
                                    {/*    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                    {/*    htmlFor="grid-email">*/}
                                    {/*    Adresse mail*/}
                                    {/*</label>*/}
                                    <Field disabled={true}
                                           label={"Adresse mail"}
                                           value={props.user.login}></Field>
                                </div>
                            </div>


                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 md:w-1/2">
                                    {/*<label*/}
                                    {/*    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                    {/*    htmlFor="grid-email">*/}

                                    {/*</label>*/}
                                    <Field disabled={true}
                                           label={"Date de naissance"}
                                           value={props.user.Consultant.birthDate ? new Date(props.user.Consultant.birthDate).toLocaleDateString("fr-FR") : ""}></Field>
                                </div>
                                <div className="w-full px-3 md:w-1/2">
                                    {/*<label*/}
                                    {/*    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                    {/*    htmlFor="grid-email">*/}

                                    {/*</label>*/}
                                    <Field disabled={true}
                                           value={props.user.Consultant.secuNum || ""}
                                    label={"Numéro de sécurité sociale"}/></div>
                            </div>

                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full px-3 md:w-1/2">
                                    <label
                                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                        htmlFor="grid-email">
                                        Adresse complète
                                    </label>
                                    <input
                                        disabled={true}
                                        value={props.user.Consultant.address || ""}
                                        // value={consultant.Consultant.address || ""}
                                        // onChange={e => {
                                        //     let c = { ...consultant };
                                        //     c.Consultant.address = e.target.value;
                                        //     setConsultant(c);
                                        // }}  
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-email" type="email" placeholder="ex: 10 rue de la paix"/>
                                </div>
                                <div className="w-full px-3 md:w-1/4">
                                    {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                    {/*    htmlFor="grid-email">*/}

                                    {/*</label>*/}
                                    <Field
                                        vtype="codePost"
                                        // required={true}
                                        label={"Code postal"}
                                        value={props.user.Consultant.zipCode || ""}
                                        // onChange={e => {
                                        //     let c = {...consultant};
                                        //     c.Consultant.zipCode = e.target.value;
                                        //     setConsultant(c);
                                        // }}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-email" type="email" placeholder="ex: 78320"/>
                                </div>
                                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">
                                    <Field
                                        label={"Ville"}
                                        // required={true}
                                        value={props.user.Consultant.city || ""}
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-city"
                                        type="text"
                                        pattern={"[0-9]"}
                                        step="0.01"
                                        placeholder="Ex: Poissy"/>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    );
}
