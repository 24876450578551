import Service from './Service';
import AuthManager from "../secu/AuthManager";

class SmsService extends Service {
    constructor() {
        super("/api/sms");
    }

    sendSms(selected, text) {
        return new Promise((resolve, reject) => {
            fetch(this.url + '/' + selected.id, {
                method: "POST",
                body: JSON.stringify({text}),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => {
                resolve(jsonresponse);
            })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

export default SmsService;
