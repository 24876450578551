import React, {Fragment, useState} from 'react'
import OkModal from "./OkModal";

export default function CraPrevHistories(props) {
    return (
        <OkModal title={props.data && props.data.title || 'Modification Previsionnel'}
                 getTools={({setOpen}) => {
                     //console.log("CRA PREV MODAL SETTOOLS",props.getTools)
                     props.getTools({setOpen});
                     // setModalOpen = setOpen;
                 }}>

            <div className="w-full px-3">
                {props.data && props.data.actions.map((action, i) => {
                    return <div key={i} style={{borderBottom: "solid 1px black"}}
                                dangerouslySetInnerHTML={{__html: action.text}}></div>;
                })}
            </div>
        </OkModal>
    )
}
