/* This example requires Tailwind CSS v2.0+ */
import React, { useRef, useState } from 'react'
import Modal from "./Modal";
import Field from '../forms/mini/Field';

export default function PasswordlModal(props) {
    let passwordRef = useRef();
    let newPasswordRef = useRef();
    let cPasswordRef = useRef();

    let [pass, setPass] = useState("")
    let [newPass, setNewPass] = useState("")
    let [confirmPass, setConfimPass] = useState("")
    let isConfirmationIdentique=()=>{
        if(newPasswordRef.current.el.value != cPasswordRef.current.el.value){
            //newPasswordRef.current.setCustomError("le mot de passe et sa confirmation ne sont pas identiques")
            newPasswordRef.current.setInvalid();
            cPasswordRef.current.setCustomError("le mot de passe et sa confirmation ne sont pas identiques")
            cPasswordRef.current.setInvalid();
            return false;
        }
        return true;
    }
    return (

        <>
            <Modal title={'Changemet de mot de pass'}
                getTools={({ setOpen }) => {
                    props.getTools({ setOpen })
                }}
                onValidate={(e, modalOpen) => {
                    if (passwordRef.current.isValid() && newPasswordRef.current.isValid() && cPasswordRef.current.isValid() && props.onValidate) {
                        if(isConfirmationIdentique()){
                            props.onValidate(e, modalOpen, { password: pass, newPass, confirmPass });
                        }
                    }
                }}>
                <Field
                    label={"Ancien mot de pass"}
                    ref={passwordRef}
                    type={"password"}
                    placeholder={"ancien mot de pass"}
                    value={pass}
                    vtype='password'
                    required
                    onChange={e => setPass(e.target.value)}
                />
                <Field
                    label={"Nouveau mot de pass"}
                    ref={newPasswordRef}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={"password"}
                    placeholder={"nouveau mot de pass"}
                    value={newPass}
                    vtype='password'
                    required
                    onChange={e => setNewPass(e.target.value)}
                />
                <Field
                    label={"Confirmation de mot de pass"}
                    ref={cPasswordRef}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    type={"password"}
                    placeholder={"confirmation de mot de pass"}
                    value={confirmPass}
                    vtype='password'
                    required
                    onChange={e => setConfimPass(e.target.value)}
                />
            </Modal>
        </>
    )
}
