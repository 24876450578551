import React from "react";
import Field from "../forms/mini/Field";
import SettingsService from "../Services/SettingsService";
import Page from "./Page";
import Swal from 'sweetalert2'
import {Switch} from "@headlessui/react"
import AuthManager from "../secu/AuthManager";
import saveAs from "../../tools/saveAs";
import {validateIcon} from "../mini/SvgIcons";

class Settings extends React.Component {
    state = {
        heurPrev: "",
        minutePrev: "",
        datePrev: "",
        heurCra: "",
        minuteCra: "",
        dateCra: "",
        sendMail: true,
        sendSms: true
    }

    constructor(props) {
        super(props);
        this.service = new SettingsService();

    }

    componentDidMount() {
        this.service.getAll().then(json => {
            console.log(json.data)

            if (json.success) {
                console.log(json.data)
                this.setState(json.data)
            }
        })
    }


    saveValuesToserver() {
        this.service.insert(this.state).then(json => {
            if (json.success) {
                console.log(json.data)
                Swal.fire({
                    icon: 'success',
                    title: 'Operation reussi',
                    text: 'La modification a bien ete prise en compte',
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: json.mesage
                })
            }
        })
    }

    render() {
        return <Page>
            <div className="container mx-auto mt-4 ">
                <div
                    className="relative flex flex-col min-w-0 break-words bg-white w-full mb-5 shadow-xl rounded-lg ">
                    <div className="p-6">
                        <h3 className={"font-bold text-lg uppercase text-gray-400 mt-3 mb-6 px-3"}>Configuration des
                            envois des Notifications de Previsionnels: </h3>
                        <div className="flex flex-wrap mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                {/*       htmlFor="grid-first-name">*/}
                                {/*    Jours du mois*/}
                                {/*</label>*/}
                                <Field
                                    label={"Jours du mois"}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="grid-first-name"
                                    required={true}
                                    vtype={"num"}
                                    step={.5}
                                    value={this.state.datePrev}
                                    onChange={e => {
                                        this.setState({datePrev: e.target.value})
                                    }}
                                    type="number"
                                    placeholder="ex: 20"/>
                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                            </div>
                            <div className="md:w-1/4 px-3">
                                {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                {/*       htmlFor="grid-last-name">*/}
                                {/*    Heure*/}
                                {/*</label>*/}
                                <Field
                                    label={"Heure"}

                                    vtype={"num"}
                                    required={true}
                                    value={this.state.heurPrev}
                                    onChange={e => {
                                        this.setState({heurPrev: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 10"/>
                            </div>
                            <div className="md:w-1/4 px-3">
                                {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                {/*       htmlFor="grid-last-name">*/}
                                {/*    Minutes*/}
                                {/*</label>*/}
                                <Field
                                    label={"Minutes"}
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.minutePrev}
                                    onChange={e => {
                                        this.setState({minutePrev: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 25"/>
                            </div>
                        </div>
                        <h3 className={"font-bold text-lg uppercase text-gray-400 mt-9 mb-6 px-3"}>Configuration des
                            envois des Notifications de Comptes rendu d'activitees: </h3>
                        <div className="flex flex-wrap  mb-6">
                            <div className="md:w-1/2 px-3">
                                {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                {/*       htmlFor="grid-last-name">*/}
                                {/*    Jours du mois*/}
                                {/*</label>*/}
                                <Field
                                    label={"Jours du mois"}
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.dateCra}
                                    onChange={e => {
                                        this.setState({dateCra: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 25"/>
                            </div>
                            <div className="md:w-1/4 px-3">
                                {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                {/*       htmlFor="grid-last-name">*/}
                                {/*    Heure*/}
                                {/*</label>*/}
                                <Field
                                    label={"Heure"}

                                    vtype={"num"}
                                    required={true}
                                    value={this.state.heurCra}
                                    onChange={e => {
                                        this.setState({heurCra: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 10"/>
                            </div>
                            <div className="md:w-1/4 px-3">
                                {/*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*/}
                                {/*       htmlFor="grid-last-name">*/}
                                {/*    Minutes*/}
                                {/*</label>*/}
                                <Field
                                    label={"Minutes"}
                                    vtype={"num"}
                                    required={true}
                                    value={this.state.minuteCra}
                                    onChange={e => {
                                        this.setState({minuteCra: e.target.value})
                                    }}
                                    step={.5}
                                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-last-name" type="number" placeholder="ex: 25"/>
                            </div>
                        </div>


                        <div className="flex flex-wrap mt-12">
                            <div className="flex justify-center w-full px-3">
                                <Switch.Group>
                                    <Switch.Label className="mr-4">activer les envoie des Emails</Switch.Label>
                                    <Switch
                                        id={"SendMailSwitch"}
                                        checked={this.state.sendMail}
                                        onChange={(e) => {
                                            this.setState({sendMail: e})
                                        }
                                        }
                                        className={`${
                                            this.state.sendMail ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                        <span className="sr-only">Enable notifications</span>
                                        <span
                                            className={`${
                                                this.state.sendMail ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white`}
                                        />
                                    </Switch>


                                    <Switch.Label className="mr-4 ml-8">activer les envoie d'sms</Switch.Label>
                                    <Switch
                                        id={"SendMailSwitch"}
                                        checked={this.state.sendSms}
                                        onChange={(e) => {
                                            this.setState({sendSms: e})
                                        }}
                                        className={`${
                                            this.state.sendSms ? 'bg-blue-600' : 'bg-gray-200'
                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    >
                                        <span className="sr-only">Enable notifications</span>
                                        <span
                                            className={`${
                                                this.state.sendSms ? 'translate-x-6' : 'translate-x-1'
                                            } inline-block h-4 w-4 transform rounded-full bg-white`}
                                        />
                                    </Switch>
                                </Switch.Group>
                            </div>


                        </div>
                    </div>

                    {this.getSqlBackupRestore()}
                    {this.getxlsBackupRestore()}

                    <div className="mt-10 py-10 border-t border-blueGray-200 text-center">
                        <div className="flex flex-wrap justify-center">
                            <div className="w-full  px-4 text-right">
                                <button
                                    className="bg-green-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={this.saveValuesToserver.bind(this)}
                                >
                                    {validateIcon}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </Page>

    }

    restore() {
        let x = document.createElement("input");
        x.setAttribute("type", "file");
        x.setAttribute("style", "display:none");
        x.setAttribute("accept", ".sql");
        x.addEventListener("change", (e) => {

            let formData = new FormData();
            //formData.append("cid", consultant.id);
            formData.append("sql", x.files[0]);

            fetch("/api/br/sql", {
                method: "POST",
                body: formData,
                headers: {
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            }) .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status == 401) {
                    AuthManager.desAuthenticateUser();
                    window.location.href = '/login';
                }
            }).then(jsonresponse => {
                console.log(jsonresponse)
            })

        })
        x.click();
    }

    backup() {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', "/api/br/sql", true);
        xhr.setRequestHeader("Authorization", "bearer " + localStorage.getItem("token"));
        xhr.responseType = 'blob';
        xhr.onload = function (e) {
            console.log(xhr.response);
            let filename = 'Backup.sql';
            saveAs(xhr.response, filename);
        };
        xhr.onerror = function (e) {
            reject(e);
            //Loader.loadstop();
        };
        xhr.send();
        // fetch("/api/br/sqlB", {
        //     method: "GET",
        //
        //     headers: {
        //         // responseType:"blob",
        //         // "content-type": 'application/json',
        //         "Authorization": "bearer " + localStorage.getItem("token")
        //     }
        // })
        //     .then(response => {
        //         if (response.ok) {
        //             console.log(response.body)
        //             return response.blob();
        //         } else if (response.status == 401) {
        //             AuthManager.desAuthenticateUser();
        //             window.location.href = '/login';
        //         }
        //
        //     })
        //     .then(json => {
        //         console.log(json)
        //         let filename = 'Backup.sql';
        //         saveAs(json, filename);
        //     })
        //     .catch(err => {
        //         console.error(err)
        //     });
    }

    getSqlBackupRestore() {
        return <div className="rounded-lg border border-gray-200/80 bg-white p-6">
            <div className="flex items-center  px-6">
                <div className="flex-1 h-8 flex-row">
                    <a href="https://github.com/EgoistDeveloper/" target="_blank">
                        <h2 className="text-lg font-semibold">Importer/Exporter des donnees SQL</h2>
                    </a>
                </div>
                <div className="w-100 flex flex-grow flex-col items-end justify-start">
                    <div className="flex flex-row space-x-3">
                        <button
                            onClick={this.backup.bind(this)}
                            className="flex rounded-md bg-blue-500 py-2 px-4 text-white transition-all duration-150 ease-in-out hover:bg-blue-600">
                            <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                 className="mr-2 w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z"/>
                            </svg>
                            Backup
                        </button>

                        <button
                            onClick={this.restore.bind(this)}
                            className="flex rounded-md bg-blue-500 py-2 px-4 text-white transition-all duration-150 ease-in-out hover:bg-blue-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="mr-2 w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z"/>
                            </svg>
                            restore
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

    getxlsBackupRestore() {
        return <div className="rounded-lg border border-gray-200/80 bg-white p-6">
            <div className="flex items-center  px-6">
                <div className="flex-1 h-8 flex-row">
                    <a href="https://github.com/EgoistDeveloper/" target="_blank">
                        <h2 className="text-lg font-semibold">Importer des donnees depuis XLS</h2>
                    </a>
                </div>
                <div className="w-100 flex flex-grow flex-col items-end justify-start">
                    <div className="flex flex-row space-x-3">

                        <button
                            className="flex rounded-md bg-blue-500 py-2 px-4 text-white transition-all duration-150 ease-in-out hover:bg-blue-600">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="mr-2 w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z"/>
                            </svg>
                            restore depuis excels
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

    getConsultantView() {
        return <div className="flex flex-row rounded-lg border border-gray-200/80 bg-white p-6">
            <div className="relative">
                <img className="w-40 h-40 rounded-md object-cover" src="https://api.lorem.space/image/face?w=150&h=150"
                     alt="User"/>

                <div
                    className="absolute -right-3 bottom-5 h-5 w-5 sm:top-2 rounded-full border-4 border-white bg-green-400 sm:invisible md:visible"
                    title="User is online"></div>
            </div>

            <div className="flex flex-col px-6">
                <div className="flex h-8 flex-row">
                    <a href="https://github.com/EgoistDeveloper/" target="_blank">
                        <h2 className="text-lg font-semibold">EgoistDeveloper</h2>
                    </a>

                    <svg className="my-auto ml-2 h-5 fill-blue-400" xmlns="http://www.w3.org/2000/svg"
                         version="1.1" width="24" height="24"
                         viewBox="0 0 24 24">
                        <path
                            d="M23,12L20.56,9.22L20.9,5.54L17.29,4.72L15.4,1.54L12,3L8.6,1.54L6.71,4.72L3.1,5.53L3.44,9.21L1,12L3.44,14.78L3.1,18.47L6.71,19.29L8.6,22.47L12,21L15.4,22.46L17.29,19.28L20.9,18.46L20.56,14.78L23,12M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9L10,17Z"/>
                    </svg>
                </div>

                <div className="my-2 flex flex-row space-x-2">
                    <div className="flex flex-row">
                        <svg className="mr-2 h-4 w-4 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg"
                             version="1.1" width="24" height="24"
                             viewBox="0 0 24 24">
                            <path
                                d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z"/>
                        </svg>

                        <div className="text-xs text-gray-400/80 hover:text-gray-400">Fullstack Developer</div>
                    </div>

                    <div className="flex flex-row">
                        <svg className="mr-2 h-4 w-4 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg"
                             version="1.1" width="24" height="24"
                             viewBox="0 0 24 24">
                            <path
                                d="M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z"/>
                        </svg>

                        <div className="text-xs text-gray-400/80 hover:text-gray-400">Istanbul</div>
                    </div>

                    <div className="flex flex-row">
                        <svg className="mr-2 h-4 w-4 fill-gray-500/80" xmlns="http://www.w3.org/2000/svg"
                             version="1.1" width="24" height="24"
                             viewBox="0 0 24 24">
                            <path
                                d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z"/>
                        </svg>

                        <div className="text-xs text-gray-400/80 hover:text-gray-400">who@am.i</div>
                    </div>
                </div>

                <div className="mt-2 flex flex-row items-center space-x-5">
                    <a href="#"
                       className="flex h-20 w-40 flex-col items-center justify-center rounded-md border border-dashed border-gray-200 transition-colors duration-100 ease-in-out hover:border-gray-400/80">
                        <div className="flex flex-row items-center justify-center">
                            <svg className="mr-3 fill-gray-500/95" xmlns="http://www.w3.org/2000/svg"
                                 version="1.1" width="24" height="24"
                                 viewBox="0 0 24 24">
                                <path
                                    d="M12,23A1,1 0 0,1 11,22V19H7A2,2 0 0,1 5,17V7A2,2 0 0,1 7,5H21A2,2 0 0,1 23,7V17A2,2 0 0,1 21,19H16.9L13.2,22.71C13,22.89 12.76,23 12.5,23H12M13,17V20.08L16.08,17H21V7H7V17H13M3,15H1V3A2,2 0 0,1 3,1H19V3H3V15M9,9H19V11H9V9M9,13H17V15H9V13Z"/>
                            </svg>

                            <span className="font-bold text-gray-600"> 4.6K </span>
                        </div>

                        <div className="mt-2 text-sm text-gray-400">Comments</div>
                    </a>

                    <a href="#"
                       className="flex h-20 w-40 flex-col items-center justify-center rounded-md border border-dashed border-gray-200 transition-colors duration-100 ease-in-out hover:border-gray-400/80">
                        <div className="flex flex-row items-center justify-center">
                            <svg className="mr-3 fill-gray-500/95" xmlns="http://www.w3.org/2000/svg"
                                 version="1.1" width="24" height="24"
                                 viewBox="0 0 24 24">
                                <path
                                    d="M2.5 19.6L3.8 20.2V11.2L1.4 17C1 18.1 1.5 19.2 2.5 19.6M15.2 4.8L20.2 16.8L12.9 19.8L7.9 7.9V7.8L15.2 4.8M15.3 2.8C15 2.8 14.8 2.8 14.5 2.9L7.1 6C6.4 6.3 5.9 7 5.9 7.8C5.9 8 5.9 8.3 6 8.6L11 20.5C11.3 21.3 12 21.7 12.8 21.7C13.1 21.7 13.3 21.7 13.6 21.6L21 18.5C22 18.1 22.5 16.9 22.1 15.9L17.1 4C16.8 3.2 16 2.8 15.3 2.8M10.5 9.9C9.9 9.9 9.5 9.5 9.5 8.9S9.9 7.9 10.5 7.9C11.1 7.9 11.5 8.4 11.5 8.9S11.1 9.9 10.5 9.9M5.9 19.8C5.9 20.9 6.8 21.8 7.9 21.8H9.3L5.9 13.5V19.8Z"/>
                            </svg>

                            <span className="font-bold text-gray-600"> 45 </span>
                        </div>

                        <div className="mt-2 text-sm text-gray-400">Projects</div>
                    </a>

                    <a href="#"
                       className="flex h-20 w-40 flex-col items-center justify-center rounded-md border border-dashed border-gray-200 transition-colors duration-100 ease-in-out hover:border-gray-400/80">
                        <div className="flex flex-row items-center justify-center">
                            <svg className="mr-3 fill-gray-500/95" xmlns="http://www.w3.org/2000/svg"
                                 version="1.1" width="24" height="24"
                                 viewBox="0 0 24 24">
                                <path
                                    d="M5.68,19.74C7.16,20.95 9,21.75 11,21.95V19.93C9.54,19.75 8.21,19.17 7.1,18.31M13,19.93V21.95C15,21.75 16.84,20.95 18.32,19.74L16.89,18.31C15.79,19.17 14.46,19.75 13,19.93M18.31,16.9L19.74,18.33C20.95,16.85 21.75,15 21.95,13H19.93C19.75,14.46 19.17,15.79 18.31,16.9M15,12A3,3 0 0,0 12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12M4.07,13H2.05C2.25,15 3.05,16.84 4.26,18.32L5.69,16.89C4.83,15.79 4.25,14.46 4.07,13M5.69,7.1L4.26,5.68C3.05,7.16 2.25,9 2.05,11H4.07C4.25,9.54 4.83,8.21 5.69,7.1M19.93,11H21.95C21.75,9 20.95,7.16 19.74,5.68L18.31,7.1C19.17,8.21 19.75,9.54 19.93,11M18.32,4.26C16.84,3.05 15,2.25 13,2.05V4.07C14.46,4.25 15.79,4.83 16.9,5.69M11,4.07V2.05C9,2.25 7.16,3.05 5.68,4.26L7.1,5.69C8.21,4.83 9.54,4.25 11,4.07Z"/>
                            </svg>

                            <span className="font-bold text-gray-600"> 120K </span>
                        </div>

                        <div className="mt-2 text-sm text-gray-400">Downloads</div>
                    </a>
                </div>
            </div>

            <div className="w-100 flex flex-grow flex-col items-end justify-start">
                <div className="flex flex-row space-x-3">
                    <button
                        className="flex rounded-md bg-blue-500 py-2 px-4 text-white transition-all duration-150 ease-in-out hover:bg-blue-600">
                        <svg className="mr-2 fill-current" xmlns="http://www.w3.org/2000/svg"
                             version="1.1" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"/>
                        </svg>

                        Follow
                    </button>

                    <button className="flex rounded-md bg-gray-100 py-2 px-1 text-white
        transition-all duration-150 ease-in-out hover:bg-gray-200">
                        <svg className="fill-gray-500" xmlns="http://www.w3.org/2000/svg"
                             version="1.1" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    }


}

export default Settings;