import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "./Page";
import EmployeurGrid from "../mini/EmployeurGrid";
import EmployeurService from "../Services/EmployeurService";
import MySwal from "sweetalert2";
import Loader from "../../tools/Loader";
import {addIcon} from "../mini/SvgIcons";

export default function Employeurs() {
    const navigate = useNavigate();
    let [employeurs, setEmployeurs] = useState([])
    let service = new EmployeurService();

    useEffect(() => {
        Loader.loadstart('chargement de la liste des employeurs');

        service.getAll().then(json => {
            if (json.success) {
                setEmployeurs(json.data);
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: json.message,
                });
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        }).finally(() => {
            Loader.loadstop();
        })
    }, [])

    let deleteEmployeur = (employeur) => {
        MySwal.fire({
            title: `Suppression de ${employeur.denominationSocial} ?`,
            text: "Êtes-vous sûr de vouloir  supprimer définitivement cet employeur!",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Oui, je supprime !",
            cancelButtonText: "Non, je ne supprime pas !",
            // closeOnConfirm: false
        }).then((res) => {
            if (res.isConfirmed) {
                Loader.loadstart(`Suppression de ${employeur.denominationSocial} ... `);
                service.delete(employeur.id).then(json => {
                    if (json.success) {
                        let e = employeurs
                        e = e.filter((em) => {
                            return em.id != employeur.id
                        })
                        setEmployeurs(e);
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        });
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    });
                }).finally(() => {
                    Loader.loadstop();
                })
            }
        })


    };


    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                className="bg-green-500  hover:bg-green-700 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={"Créer un nouvel employeur"}
                onClick={() => {
                    navigate('/employeurs/create')
                }}
                data-cy={"New-Employeur-Btn"}>
                {addIcon}
            </button>
        </div>
        <EmployeurGrid
            onEdit={e => {
                navigate('/employeurs/create/' + e.id);
            }}
            employeurs={employeurs}
            onDelete={deleteEmployeur}/>
    </Page>;
}
