import React from "react";
import {Link} from "react-router-dom";



export default function ResetPassPerime(props) {
    return <div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <img src="/img/milogo.png"></img>

        </div>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}>
            <div className={"text-center"}>
                <p> Vous ne pouvez plus utiliser ce liens, Ce liens est périmé</p>
                <br/> <br/>
                <p>Veuillez Redemander un autre liens<br/> <br/>
                    {/*<Link*/}
                    {/*className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"}*/}
                    {/*to={"/login"}> Je me connect à mon profile</Link>*/}
                </p>

            </div>
        </div>
    </div>
}
