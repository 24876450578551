import {deleteIcon, editIcon} from "../SvgIcons";
import React from "react";

export default function AdminRow(props) {

    let person = props.admin;

    return <tr key={person.id} onDoubleClick={e => {
        e.preventDefault();
        if (props.onEdit && typeof props.onEdit === "function") {
            props.onEdit(person)
        }
    }}>
        <td className="px-6 py-1 whitespace-nowrap">
            <div className="flex items-center">

                {/*<div className="ml-4">*/}
                <div
                    className="ml-4 text-sm font-medium text-gray-900"> {person.firstname} </div>

                {/*</div>*/}
            </div>
        </td>
        <td className="px-6 py-1 whitespace-nowrap">
            <div className="flex items-center">

                {/*<div className="ml-4">*/}
                <div
                    className="ml-4 text-sm font-medium text-gray-900"> {person.lastname}</div>
                {/*</div>*/}
            </div>
        </td>
        <td className="px-6 py-1 whitespace-nowrap">
            <div className="text-sm text-gray-500">{person.login}</div>
        </td>
        <td className="px-6 py-1 whitespace-nowrap ">
            <div className="text-sm text-gray-500">{person.phone}</div>

        </td>

        <td className="px-6 py-1 whitespace-nowrap text-right text-sm font-medium w-1">
            <button
                className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={`Editer l'administrateur : ${person.lastname} ${person.firstname}`}
                onClick={e => {
                    e.preventDefault();
                    if (props.onEdit && typeof props.onEdit === "function") {
                        props.onEdit(person)
                    }
                }}
            >
                {editIcon}
            </button>
            <button
                className="bg-red-500 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={`Supprimer l'administrateur : ${person.lastname} ${person.firstname}`}
                onClick={e => {
                    e.preventDefault();
                    if (props.onDelete && typeof props.onDelete === "function") {
                        props.onDelete(person)
                    }
                }}
            >
                {deleteIcon}
            </button>
        </td>
    </tr>;





}