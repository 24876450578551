import React, {useContext, useEffect, useState} from "react";
import Page from "./Page";
import FileUploaderRow from "../mini/FileUploaderRow";
import AuthenticationContext from "../secu/AuthenticationContext";
import ConsultantService from "../Services/ConsultantService";
import MySwal from "sweetalert2";
import FileUploader from "../mini/FileUploader";


export default function Documents() {
    const authContext = useContext(AuthenticationContext);
    let service = new ConsultantService();
    let connected = authContext.getAuthenticationData();
    let [docs1, setDocs1] = useState({
        cv: {},
        pi: {},
        jd: {},
        ass: {},
        cg: {},
        cm: {},
        ap: {},
        ib: {}
    });


    useEffect(() => {
        service.readDocs1(connected.ConsultantId).then((json => {
            if (json.success) {
                setDocs1(json.data)
                console.log(json)
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: json.message,
                });
            }
        })).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        })
    }, [])
    let downloadDoc = (doc) => {
        service.downloadDocs1(doc).then(() => {
            console.log("DOWNLOAD", doc)
        });
    };
    let getConsultantDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">

            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents d'identité du
                consultant: {connected.firstname} {connected.lastname}</h3>

            <div className="flex flex-wrap -mx-3 mb-6">
                {/*<div className="docLine">*/}
                {/*    <span>Document</span>*/}
                {/*    <span>Nom du fichier</span>*/}
                {/*    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                {/*</div>*/}
                <FileUploader
                    readOnly={true}
                    label="CV"
                    ondownload={downloadDoc}
                    doc={docs1.cv}></FileUploader>

                <FileUploader
                    readOnly={true}
                    female={true}
                    label="Pièce d'identité"
                    ondownload={downloadDoc}
                    doc={docs1.pi}></FileUploader>
                <FileUploader
                    readOnly={true}
                    label="Justificatif de domicile"
                    ondownload={downloadDoc}
                    doc={docs1.jd}></FileUploader>
                <FileUploader
                    readOnly={true}
                    female={true}
                    label="Attestation de sécurité sociale"
                    ondownload={downloadDoc}
                    doc={docs1.ass}></FileUploader>
                <FileUploader
                    readOnly={true}
                    female={true}
                    label="Carte grise"
                    ondownload={downloadDoc}
                    doc={docs1.cg}></FileUploader>
                <FileUploader
                    readOnly={true}
                    female={true}
                    label="Information bancaire"
                    ondownload={downloadDoc}
                    doc={docs1.ib}></FileUploader>

            </div>
        </form>
    }
    let getConsultantHealthDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">


            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents de Santé du
                consultant: {connected.firstname} {connected.lastname}</h3>


            <div className="flex flex-wrap -mx-3 mb-6">
                {/*<div className="docLine">*/}
                {/*    <span>Document</span>*/}
                {/*    <span>Nom du fichier</span>*/}
                {/*    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                {/*</div>*/}
                <FileUploader label="Contrat mutuelle"
                                 readOnly={true}
                                 ondownload={downloadDoc}
                                 doc={docs1.cm}></FileUploader>
                <FileUploader label="Attestation de prévoyance"
                                 readOnly={true}
                                 ondownload={downloadDoc}
                                 doc={docs1.ap}></FileUploader>

            </div>


        </form>

    }
    return <Page>
        {getConsultantDocsForm()}
        {getConsultantHealthDocsForm()}
    </Page>;
}
