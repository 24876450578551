import Login from "./cmps/pages/Login";
import Settings from "./cmps/pages/Settings";
import RequireAuth from "./cmps/secu/RequireAuth";
import ProfilePage from "./cmps/pages/ProfilePage";
import Messages from "./cmps/pages/Messages";
import Employeurs from "./cmps/pages/Employeurs";
import Missions from "./cmps/pages/Missions";
import Consultants from "./cmps/pages/Consultants";
import CompeteProfile from "./cmps/pages/CompleteProfile";
import DashBoard from "./cmps/pages/DashBoard";
import EmployeurForm from "./cmps/forms/EmployeurForm";
import MiniConsultantForm from "./cmps/forms/ConsultantForm";
import Prev from "./cmps/pages/Prev";
import Cra from "./cmps/pages/Cra";
import Perime from "./cmps/pages/Perime";
import MissionForm from "./cmps/forms/MissionForm";
import Clients from "./cmps/pages/Clients";
import ClientForm from "./cmps/forms/ClientForm";
import Error500 from "./cmps/pages/Error500";
import ResetPasswordPage from "./cmps/pages/ResetPasswordPage";
import SecuredResetPasswordPage from "./cmps/pages/SecuredResetPasswordPage";
import AdminsCrud from "./cmps/pages/AdminsCrud";
import AdminsForm from "./cmps/forms/AdminsForm";
import ConsultantFullForm from "./cmps/forms/ConsultantFullForm";
import Actions from "./cmps/pages/Actions";
import ResetPassPerime from "./cmps/pages/ResetPassPerime";
import RequireSuperAdmin from "./cmps/secu/RequireSuperAdmin";
import RequireAdmin from "./cmps/secu/RequireAdmin";
import SMSPage from "./cmps/pages/SMSPage";
import Documents from "./cmps/pages/Documents";


export default [
    { path: "/login", component: Login },
    { path: "/reset", component: ResetPasswordPage },
    { path: "/resetpass/:token", component: SecuredResetPasswordPage },
    { path: "/admins/create/:id", component: RequireSuperAdmin(AdminsForm) },
    { path: "/admins/create", component: RequireSuperAdmin(AdminsForm) },
    { path: "/admins", component: RequireSuperAdmin(AdminsCrud) },
    { path: "/settings", component: RequireSuperAdmin(Settings) },
    { path: "/profile", component: RequireAuth(ProfilePage) },
    { path: "/docs", component: RequireAuth(Documents) },
    { path: "/completeProfile/:id", component: CompeteProfile },
    { path: "/actions", component: RequireSuperAdmin(Actions) },
    { path: "/Prev/:id", component: Prev },
    { path: "/Cra/:id", component: Cra },
    { path: "/Perime/:id", component: Perime },
    { path: "/resetPassPerime/:id", component: ResetPassPerime },
    { path: "/messages", component: RequireAuth(Messages) },
    { path: "/missions/create", component: RequireAdmin(MissionForm) },
    { path: "/missions/update", component: RequireAdmin(MissionForm) },
    { path: "/employeurs/create/:id", component: RequireAdmin(EmployeurForm) },
    { path: "/employeurs/create", component: RequireAdmin(EmployeurForm) },
    { path: "/employeurs", component: RequireAdmin(Employeurs) },
    { path: "/missions/docs", component: RequireAdmin(Missions) },
    { path: "/missions", component: RequireAdmin(Missions) },
    { path: "/clients/create/:id", component: RequireAdmin(ClientForm) },
    { path: "/clients/create", component: RequireAdmin(ClientForm) },
    { path: "/error", component: RequireAuth(Error500) },
    { path: "/clients", component: RequireAdmin(Clients) },
    { path: "/admin", component: RequireAdmin(Missions) },
    { path: "/consultant/create/:id", component: RequireAdmin(ConsultantFullForm) },
    { path: "/consultant/create", component: RequireAdmin(MiniConsultantForm) },
    { path: "/consultant", component: RequireAdmin(Consultants) },
    { path: "/sms", component: RequireAdmin(SMSPage) },
    { path: "/", component: RequireAuth(DashBoard) }
]
