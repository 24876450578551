import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Page from "./Page";
import AdminsService from "../Services/AdminsService";
import AdminGrid from "../mini/AdminGrid";
import MySwal from "sweetalert2";
import Loader from "../../tools/Loader";
import {addIcon} from "../mini/SvgIcons";


export default function AdminsCrud() {
    const navigate = useNavigate();
    let [admins, setAdmins] = useState([])
    let service = new AdminsService();
    useEffect(() => {
        /*  db.Consultant.toArray().then(array=>{
              setConsultants(array)
          })*/
        Loader.loadstart('chargement de la liste des administrateurs');
        service.getAll().then(json => {
            if (json.success) {
                setAdmins(json.data);
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: json.message,
                });
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            });
        }).finally(() => {
            Loader.loadstop();
        });
    }, [])

    let deleteConsultant = (consultant) => {


        MySwal.fire({
            title: `Suppression de ${consultant.firstname}  ${consultant.lastname} ?`,
            text: "Êtes-vous sûr de vouloir  supprimer définitivement cet admin!",
            // type: "question",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Oui, je supprime !",
            cancelButtonText: "Non, je ne supprime pas !",
            closeOnConfirm: false
        }).then((res) => {
            if (res.isConfirmed) {
                Loader.loadstart(`Suppression de ${consultant.firstname}  ${consultant.lastname}`)
                service.delete(consultant.id).then(json => {
                    if (json.success) {
                        let e = admins
                        e = e.filter((em) => {
                            return em.id != consultant.id
                        })
                        setAdmins(e)
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    });
                }).finally(() => {
                    Loader.loadstop();
                })
            }
        })

    }


    return <Page>


        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                className="bg-green-500 hover:bg-green-700 active:bg-green-600  uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={"Créer un nouvel administrateur"}
                onClick={() => {
                    navigate('/admins/create')
                }}
            >
                {addIcon}
            </button>
        </div>
        <AdminGrid
            admins={admins}
            onEdit={consultant => {
                navigate("/admins/create/" + consultant.id)
                //  console.log("editing", consultant)
            }}
            onDelete={deleteConsultant}
        />
    </Page>;
}
