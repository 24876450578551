let startTime,timer;

class Loader {
    static loadstart(TextMessage, timout = 1) {
        startTime = Date.now();
        timer = timout;
        let loader = document.createElement('div');
        loader.className = "loader";
        loader.id = "loaderazertyiop";
        let grid = document.createElement('div');
        grid.className = "lds-grid";
        grid.innerHTML = `<div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>`;
        loader.appendChild(grid)
        if (TextMessage) {
            let texts = document.createElement('span');
            texts.innerHTML = TextMessage;
            loader.appendChild(texts)
        }
        document.body.appendChild(loader)

    }

    static loadstop() {
        let callStopTime = Date.now();
        let a = document.getElementById('loaderazertyiop');
        let int = callStopTime - startTime
        if (timer - int < 0) {
            if (a) {
                a.remove();
            }
        } else {
            setTimeout(() => {
                if (a) {
                    a.remove();
                }
            }, timer - int)
        }

    }
}


export default Loader;
