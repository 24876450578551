import React, { useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Page from "./Page";
import MissionGrid from "../mini/MissionGrid";
import MissionsService from "../Services/MissionsService";
import MySwal from "sweetalert2";
import Loader from "../../tools/Loader";


export default function Missions() {
    let navigate = useNavigate();
    let [missions, setMissions] = useState([]);
    let service = new MissionsService();
    let location = useLocation();
    useEffect(() => {
        service.getAll().then(json => {
            if (json.success) {
                setMissions(json.data);
            }
        })
    }, [])
    let editMission = (mission) => {
        // console.log(mission)
        mission.backTo = location.pathname;
        navigate('/missions/update', { state: mission })
        // console.log("editing", mission)
    }


    let showMissionDocs = (mission) => {
        navigate('/missions/docs', mission)

    }

    let deleteMission = (mission) => {
        MySwal.fire({
            title: `Suppression  ?`,
            text: "Êtes-vous sûr de vouloir  supprimer définitivement cet Mission!",
            // type: "question",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Oui, je supprime !",
            cancelButtonText: "Non, je ne supprime pas !",
            closeOnConfirm: false
        }).then((res) => {
            if (res.isConfirmed) {
                Loader.loadstart(`Suppression de ${mission.title}`)
                service.delete(mission.id).then(json => {
                    console.log("Deleted", jsonSrv);
                    if (json.success) {
                        let m = missions.filter((missionInState) => {
                            return missionInState.id != mission.id;
                        });
                        setMissions(m)
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }


                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    });
                }).finally(() => {
                    Loader.loadstop();
                })
            }
        })

    };
    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            {/* <button
                className="bg-gray-500 active:bg-gray-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={e => {
                    history.push('/missions/create')
                }}
            >
                Nouvelle Mission
            </button> */}
        </div>
        <MissionGrid onDocument={showMissionDocs} missions={missions} onEdit={editMission} onDelete={deleteMission}></MissionGrid>
    </Page>;
}
