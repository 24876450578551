/* This example requires Tailwind CSS v2.0+ */
import React, {useState} from 'react'
import Modal from "../mini/Modal";
import MySwal from "sweetalert2";
import DateField from "./mini/DateField";
import MissionsService from "../Services/MissionsService";


export default function ModalCloseMissionForm(props) {
    let setModalOpen;
    const [mission, setMission] = useState({})
    let dateEndF;
    let service = new MissionsService();
    let submitForm = () => {
        if (isValid()) {
            mission.tjm = mission.tjm + ""
            service.update(mission).then(res => {
                if (res.success) {
                    props.aftersave && props.aftersave(res.data);
                    setModalOpen(false)
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res.message,
                    })
                }
            });
        }

    }
    let isValid = () => {
        return dateEndF && dateEndF.isValid();
    }
    return (<Modal
        title={'Cloturer la mission ' + mission.title + (mission && mission.Client && (' chez ' +  mission.Client.denominationSocial))}
        onValidate={(e, closeFn, mission) => {
            setModalOpen = closeFn;
            submitForm();
        }}
        getTools={({setOpen}) => {
            setModalOpen = setOpen;
            props.getTools({setOpen, setMission});
            // setModalOpen = setOpen;
        }}>

        <div className="w-full px-3" style={{minHeight: "300px"}}>
            <DateField
                label={"Date de fin de mission"}
                value={mission.dateEnd || ""}
                ref={(r) => {
                    dateEndF = r;
                }}
                required={true}
                onChange={e => {
                    let em = {...mission};
                    em.dateEnd = e;
                    setMission(em);
                }}
                enteraction={submitForm}
                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-denomination-sociale" type="text" placeholder="ex: Citeo"/>
        </div>

        {/*<div className="w-full px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    RCS*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"RCS"}*/}
        {/*        ref={(r) => {*/}
        {/*            rcsF = r;*/}
        {/*        }}*/}
        {/*        enteraction={submitForm}*/}
        {/*        value={client.rcs || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.rcs = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: Ville 000 000 000"/>*/}
        {/*</div>*/}


        {/*<div className="w-full  px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    Siret*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"siren"}*/}
        {/*        ref={(r) => {*/}
        {/*            siretF = r;*/}
        {/*        }}*/}
        {/*        enteraction={submitForm}*/}
        {/*        value={client.siret || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.siret = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: 84074430400022"/>*/}
        {/*</div>*/}

        {/*<div className="w-full px-3">*/}
        {/*    <Field*/}
        {/*        label={"Président"}*/}
        {/*        ref={(r) => {*/}
        {/*            presidentF = r;*/}
        {/*        }}*/}
        {/*        enteraction={submitForm}*/}
        {/*        value={client.president || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.president = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: jean mouloud de montalambere"/>*/}
        {/*</div>*/}
        {/*<div className="w-full  px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    TVA intercom.*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"TVA intercom."}*/}
        {/*        ref={(r) => {*/}
        {/*            tvaIntracomF = r;*/}
        {/*        }}*/}
        {/*        enteraction={submitForm}*/}
        {/*        value={client.tvaIntracom || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.tvaIntracom = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: FR0000000000"/>*/}
        {/*</div>*/}


        {/*<div className="w-full  px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    contact*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        ref={(r) => {*/}
        {/*            contactF = r;*/}
        {/*        }}*/}
        {/*        enteraction={submitForm}*/}
        {/*        label={"contact"}*/}
        {/*        value={client.contact || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.contact = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: moha begdadi"/>*/}
        {/*</div>*/}


        {/*<div className="w-full px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    contact phone*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"contact phone"}*/}
        {/*        ref={(r) => {*/}
        {/*            contactPhoneF = r;*/}
        {/*        }}*/}
        {/*        value={client.contactPhone || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.contactPhone = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}

        {/*        enteraction={submitForm}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: 0600000000"/>*/}
        {/*</div>*/}
        {/*<div className="w-full px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    contact email*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"contact email"}*/}
        {/*        ref={(r) => {*/}
        {/*            contactMailF = r;*/}
        {/*        }}*/}
        {/*        value={client.contactMail || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.contactMail = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}

        {/*        enteraction={submitForm}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: user@exaple.com"/>*/}
        {/*</div>*/}

        {/*<div className="w-full  px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    address*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"address"}*/}
        {/*        ref={(r) => {*/}
        {/*            addressF = r;*/}
        {/*        }}*/}
        {/*        value={client.address || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.address = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}

        {/*        enteraction={submitForm}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex:  12 rue de la reine Amelie"/>*/}
        {/*</div>*/}
        {/*<div className="w-full px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    code postal*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"code postal"}*/}
        {/*        ref={(r) => {*/}
        {/*            zipcodeF = r;*/}
        {/*        }}*/}
        {/*        value={client.zipCode || ""}*/}
        {/*        required={true}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.zipCode = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}

        {/*        enteraction={submitForm}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: 94000"/>*/}

        {/*</div>*/}
        {/*<div className="w-full  px-3">*/}
        {/*    /!*<label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"*!/*/}
        {/*    /!*    htmlFor="grid-last-name">*!/*/}
        {/*    /!*    ville*!/*/}
        {/*    /!*</label>*!/*/}
        {/*    <Field*/}
        {/*        label={"Ville"}*/}
        {/*        value={client.city || ""}*/}
        {/*        required={true}*/}
        {/*        ref={(r) => {*/}
        {/*            cityF = r;*/}
        {/*        }}*/}
        {/*        onChange={e => {*/}
        {/*            let em = {...client};*/}
        {/*            em.city = e.target.value;*/}
        {/*            setClient(em);*/}
        {/*        }}*/}

        {/*        enteraction={submitForm}*/}
        {/*        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"*/}
        {/*        id="grid-last-name" type="text" placeholder="ex: Bobiny"/>*/}
        {/*</div>*/}
    </Modal>)
}