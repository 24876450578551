import React from 'react';
import DatePicker, {registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);


class DateField extends React.Component {
    constructor() {
        super();
        this.id = '';
        this.state = {
            labelClassName: ''
        };
    }

    componentDidMount() {
        this.el.input.addEventListener('keypress', this.onKeyUp.bind(this));

    }

    onKeyUp(e) {
        if (e.key === "Enter" && this.props.enteraction) {
            this.props.enteraction(e, this);
        }
    }

    isValid() {
        return this.validate(true);
    }

    validate(blure = false) {
        if (blure) {
            this.setState({focusCount: this.state.focusCount + 1});
        }
        if (this.state.focusCount > 0 || blure) {
            const {required, vtype, vtypeMsg} = this.props;


            if (this.el.input.value !== '') {
                let n = Date.now();
                let d = this.el.input.value.split('/');
                let tmp = d[0];
                d[0] = d[1];
                d[1] = tmp;
                let s = Date.parse(d.join("/") + " 23:59");
                // console.log("n", n, "s", s)
                this.setState({errmsg: ``});
                switch (vtype) {
                    case 'future':
                        if (n > s) {
                            this.setState({errmsg: vtypeMsg || `Doit être dans le future.`});
                            this.el.input.classList.remove('valid');
                            this.el.input.classList.add('invalid');
                            return false;
                        } else {
                            this.setState({errmsg: ``});
                            this.el.input.classList.remove('invalid');
                            this.el.input.classList.add('valid');
                            return true;
                        }
                    case 'past':

                        if (n < s) {
                            this.setState({errmsg: vtypeMsg || `Doit être dans le passé.`});
                            this.el.input.classList.remove('valid');
                            this.el.input.classList.add('invalid');
                            return false;
                        } else {
                            this.setState({errmsg: ``});
                            this.el.input.classList.remove('invalid');
                            this.el.input.classList.add('valid');
                            return true;
                        }
                    case 'pastToday':
                        s = Date.parse(d.join("/") + " 00:00");
                        // console.log("n", n, "s", s);
                        if (n <= s) {
                            this.setState({errmsg: vtypeMsg || `Doit être dans le passé, ou aujourd'hui.`});
                            this.el.input.classList.remove('valid');
                            this.el.input.classList.add('invalid');
                            return false;
                        } else {
                            this.setState({errmsg: ``});
                            this.el.input.classList.remove('invalid');
                            this.el.input.classList.add('valid');
                            return true;
                        }
                    default:
                        this.setState({errmsg: ``});
                        this.el.input.classList.remove('invalid');
                        this.el.input.classList.add('valid');
                        return true;
                }

            } else {
                if (required) {
                    this.setState({errmsg: `Ne doit pas être vide.`});
                    this.invalidate();
                    return false;
                } else {
                    this.setState({errmsg: ``});
                    this.el.input.classList.remove('invalid');
                    this.el.input.classList.add('valid');
                    return true;
                }
            }

        }

    }

    getErrorView() {
        if (this.state.errmsg != null && this.state.errmsg !== "") {
            return <div className="helper-text">{this.state.errmsg}</div>;
        }
    }

    invalidate() {
        this.el.input.classList.remove('valid');
        this.el.input.classList.add('invalid');
    }

    onBlur(e) {
        e.stopPropagation();
        e.preventDefault();
        if ((this.props.placeholder !== '') || (this.el && this.el.value !== "")) {
            if (!this.label.classList.contains("active")) {
                this.label.classList.add('active');
            }
        } else {
            this.label.classList.remove('active');
        }
        if (this.props.onBlur && typeof this.props.onBlur === "function") {
            this.props.onBlur();
        }
        this.validate(true);
        this.setState({focusCount: this.state.focusCount + 1});
    }

    render() {
        return (
            <div>
                {/* {this.getIcon()} */}
                <label ref={ref => (this.label = ref)}
                       htmlFor={this.id}
                       className={this.state.labelClassName}>{this.props.label + (this.props.required === true ? ' *' : '')}</label>
                <DatePicker
                    value={Date.parse(this.props.value)}
                    onKeyDown={this.onKeyUp.bind(this)}
                    ref={ref => {
                        this.el = ref;
                    }}
                    id={this.id}
                    style={{marginTop: 0}}
                    onBlur={(e) => this.onBlur.bind(this)(e)}
                    onFocus={e => this.setState({focusCount: this.state.focusCount + 1})}
                    onChange={this.props.onChange}
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    dateFormat={"dd/MM/yyyy"}
                    selected={Date.parse(this.props.value)}/>
                {this.getErrorView()}

            </div>
        );
    }
}

DateField.defaultProps = {
    type: 'date',
    className: '',
    placeholder: '',
    label: ''
};
export default DateField;
