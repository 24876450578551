import {Menu, Transition} from "@headlessui/react";
import React, {Fragment, useContext, useState} from "react"
import {addIcon, downloadIcon, editIcon, uploadIcon, viewIcon} from "./SvgIcons";
import AuthenticationContext from "../secu/AuthenticationContext";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function FileUploader(props) {
    let [dd, setDd] = useState(false);
    let [fn, setFn] = useState("Aucun fichier selectionne");
    const authContext = useContext(AuthenticationContext);

    let getFileIcon = () => {
        let t;
        if (props.doc && props.doc.type) {
            t = props.doc.type;
        } else if (props.doc && props.doc.mimetype) {
            t = props.doc.mimetype;
        }
        if (props.doc && t == "application/pdf") {
            return <img src={"/images/docs/pdf.webp"}/>;
        } else if (props.doc && t && t.indexOf("image") != -1) {
            return <img src={"/images/docs/png.webp"}/>;
        } else {
            return <img src={"/images/docs/txt.webp"}/>;
        }
    }

    let getButtons = () => {
        if (props.doc && props.doc.name) {
            // return <Menu as="div" className="ml-3 relative z-100">
            //     <div>
            //         <Menu.Button
            //             className=" flex text-sm">
            //             <img src={"/images/tools/more.webp"} />
            //         </Menu.Button>
            //     </div>
            //     <Transition
            //         as={Fragment}
            //         className={"dd"}
            //         enter="transition ease-out duration-100"
            //         enterFrom="transform opacity-0 scale-95"
            //         enterTo="transform opacity-100 scale-100"
            //         leave="transition ease-in duration-75"
            //         leaveFrom="transform opacity-100 scale-100"
            //         leaveTo="transform opacity-0 scale-95"
            //     >
            //         <Menu.Items
            //             className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            //             <Menu.Item>
            //                 {({ active }) => (
            //                     <a
            //                         onClick={props.onpreview && typeof props.onpreview == "function" && (e => props.onpreview(props.doc))}
            //                         className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
            //                     >
            //                         Aperçu
            //                     </a>
            //                 )}
            //             </Menu.Item>
            //
            //
            //             <Menu.Item>
            //                 {({ active }) => (
            //                     <a
            //                         onClick={props.ondownload && typeof props.ondownload == "function" && (e => props.ondownload(props.doc))}
            //                         className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
            //                     >
            //                         Télécharger
            //                     </a>
            //                 )}
            //             </Menu.Item>
            //         </Menu.Items>
            //     </Transition>
            // </Menu>;
            return <div className="ml-3 relative z-100  flex text-sm">

                {authContext.isAdmin() && <button
                    title={"Charger un nouveau document"}
                    data-cy={"New-Client-Btn"}
                    className="bg-yellow-600 hover:bg-yellow-700 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={openFileChoser}> {editIcon}
                </button>}
                <button
                    title={"Télécharger le document"}
                    data-cy={"New-Client-Btn"}
                    className="bg-blue-500  hover:bg-blue-700 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={props.ondownload && typeof props.ondownload == "function" && (e => props.ondownload(props.doc))}> {downloadIcon}
                </button>
                <button
                    title={"Apperçu du document"}
                    data-cy={"New-Client-Btn"}
                    className="bg-blue-400  hover:bg-blue-600 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={props.onpreview && typeof props.onpreview == "function" && (e => props.onpreview(props.doc))}> {viewIcon}
                </button>
            </div>
        } else {
            return <div className="ml-3 relative z-100  flex text-sm">
                {authContext.isAdmin() &&  <button
                    data-cy={"New-Client-Btn"}
                    className="bg-green-500  hover:bg-green-700 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={openFileChoser}> {addIcon}
                </button>}

            </div>
        }

    };
    let openFileChoser = () => {
        let x = document.createElement("input");
        x.setAttribute("type", "file");
        x.setAttribute("style", "display:none");
        x.addEventListener("change", (e) => {
            setFn(x.files[0].name + " -->  " + x.files[0].size);
            props.onChange && props.onChange(x.files[0], e);
        })
        x.click();
    }

    return <div className="docLine">
        <span>{getFileIcon()}{props.label || "Aucun label"}</span><span>{(props.doc && props.doc.name) || "Aucun fichier"}</span>
        <span className="trigger">{getButtons()}</span>
    </div>;

}