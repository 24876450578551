import Service from './Service';
import saveAs from '../../tools/saveAs';

class CraService extends Service {
    constructor() {
        super('/api/cra');
    }

    dowmloadCra(doc) {
        let craDownloadURL = this.url + "/download/" + doc.id;

        return new Promise((resolve, reject) => {
            fetch(craDownloadURL).then(response => response.blob()).then((blob) => {
                saveAs(blob, doc.craFilename);
                 resolve()
            }).catch(r=>{
                reject(r)
            })


        })
    }
}

export default CraService;
