import React, {useEffect, useState} from "react"
import {useNavigate, useParams} from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import "./react-datepicker.scss";
import AdminsService from "../Services/AdminsService";
import MySwal from "sweetalert2";
import Field from "./mini/Field";
import Loader from "../../tools/Loader";
import {cancelIcon, validateIcon} from "../mini/SvgIcons";

export default function AdminsForm(props) {
    let params = useParams();
    let firstnameF, lastnameF, emailF, phoneF;
    let isFormValid = () => {
        return firstnameF && firstnameF.isValid() &&
            lastnameF && lastnameF.isValid() &&
            emailF && emailF.isValid() &&
            phoneF && phoneF.isValid();
    }
    let service = new AdminsService();
    let submitForm = function () {
        if (isFormValid()) {
            if (params.id) {
                //je suis en modif
                Loader.loadstart(`Mise a jour de ${consultant.firstname}  ${consultant.lastname}`)
                service.update(consultant).then(json => {
                    if (json.success) {
                        navigate("/admins");
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                }).finally(() => {
                    Loader.loadstop();
                });
            } else {
                //je suis en ajout
                Loader.loadstart(`Creation de ${consultant.firstname}  ${consultant.lastname}`)
                   service.insert(consultant) .then(json => {
                        if (json.success) {
                            navigate("/admins");
                        } else {
                            MySwal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: json.message,
                            })
                        }
                    }).catch(err => {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: err.message,
                        })
                    }).finally(() => {
                    Loader.loadstop();
                });
            }
        }

    }

    const navigate = useNavigate();
    let [consultant, setConsultant] = useState({
        firstname: "",
        login: "",
        phone: "",
        lastname: ""
    });


    useEffect(() => {
        if (params.id) {
            service.getOne(params.id)
                .then(json => {
                    if (json.success) {
                        setConsultant(json.data);
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: err.message,
                })
            });
        }
    }, [])

    return <div className={"grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200 px-12"}>
        <div role="Form" className={"col-span-2"}>
            <form className="flex flex-col justify-center p-10">
                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Administrateur :</h3>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <Field
                            label={"Nom"}
                            data-cy="firstname"
                            ref={(r) => {
                                firstnameF = r;
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            required={true}
                            min={2}
                            max={150}
                            value={consultant.firstname || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.firstname = e.target.value;
                                setConsultant(c);
                            }}
                            enteraction={submitForm}
                            type="text"
                            placeholder="ex: Dupont"/>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <Field
                            label={"Prénom"}
                            data-cy="lastname"
                            ref={(r) => {
                                lastnameF = r;
                            }}
                            min={2}
                            max={150}
                            value={consultant.lastname || ""}
                            required={true}
                            onChange={e => {
                                let c = {...consultant};
                                c.lastname = e.target.value;
                                setConsultant(c);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-last-name" type="text" placeholder="ex: Romain"/>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3 md:w-1/2">
                        <Field
                            label={"email"}
                            data-cy="email"
                            ref={(r) => {
                                emailF = r;
                            }}
                            vtype={'email'}
                            required={true}
                            value={consultant.login || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.login = e.target.value;
                                setConsultant(c);
                            }}
                            enteraction={submitForm}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-email" type="email" placeholder="ex: user@exaple.com"/>
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <Field
                            label={"Téléphone"}
                            data-cy="phone"
                            required={true}
                            ref={(r) => {
                                phoneF = r;
                            }}
                            enteraction={submitForm}
                            vtype={'phone'}
                            value={consultant.phone || ""}
                            onChange={e => {
                                let c = {...consultant};
                                c.phone = e.target.value;
                                setConsultant(c);
                            }}
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-city"
                            type="text"
                            placeholder="0600000000"/>
                    </div>
                </div>
                <div className="pt-5 flex justify-between">
                    <button
                        className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        title={"Annuler"}
                        onClick={e => {
                            navigate("/admins");
                        }}
                    >{cancelIcon}
                    </button>
                    <button
                        className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        title={"Valider"}
                        onClick={submitForm}>
                        {validateIcon}
                    </button>
                </div>
            </form>
        </div>
    </div>
}
