import React, {useEffect} from "react";


export default function SmsViewer(props) {
    let el;
    useEffect(() => {
        el && (el.scrollTop = el.scrollHeight);
    })
    props.getAutosizeFn && props.getAutosizeFn(()=>el && (el.scrollTop = el.scrollHeight));
    return (<>
        <ul id={"chat"} ref={rrrr => {
            el = rrrr;
        }} className="smsZone">
            {props.smsList.map(sms => {
                return <li key={sms.id} className={sms.to === "33644637511" ? "me" : "you"}>
                    <div className="entete">
                        <span className="status green"></span>
                        <h2>{props.consultant.Utilisateur.firstname} {props.consultant.Utilisateur.lastname} ({sms.msisdn})</h2>
                        <h3>&nbsp;&nbsp;&nbsp; {new Date(sms.createdAt).toLocaleDateString("FR-fr")}</h3>
                    </div>
                    <div className="triangle"></div>
                    <div className="message">
                        {sms.text}
                    </div>
                </li>
            })}
        </ul>
    </>);
}
