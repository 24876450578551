import React, {useEffect, useState} from "react"
import {useLocation, useNavigate, useParams} from "react-router-dom";
import AuthManager from "../secu/AuthManager";
import "./react-datepicker.scss";
import ConsultantService from "../Services/ConsultantService";
import Field from "./mini/Field";
import Page from "../pages/Page";
import {CAChart} from "../mini/Charts/CAChart";
import MissionTabs from "../mini/MissionTabs";
import CraService from "../Services/CraService";
import PrevService from "../Services/PrevService";
import {validateIcon, editIcon, cancelIcon, addMissionIcon} from "../mini/SvgIcons";
import MySwal from "sweetalert2";
import FileUploader from "../mini/FileUploader";
import ModalCloseMissionForm from "./ModalCloseMissionForm";
import DateField from "./mini/DateField";
import ModalCrteateActivityForm from "./ModalCrteateActivityForm";


export default function ConsultantFullForm(props) {

    const navigate = useNavigate();
    let location = useLocation()
    let params = useParams()
    let consultantToEdit = location.state;
    let [docs1ro, setDocs1ro] = useState(true);
    let [docs2ro, setDocs2ro] = useState(true);
    let [infosro, setInfosro] = useState(true);
    let firstnameF, lastnameF, emailF, phoneF, dateNaissanceF, secuF, addressF, zipcodeF, cityF, openCloseMissionModal,
        setMissionToClose;

    let isFormValid = () => {
        return firstnameF && firstnameF.isValid() &&
            lastnameF && lastnameF.isValid() &&
            emailF && emailF.isValid() &&
            phoneF && phoneF.isValid() &&
            secuF && secuF.isValid() &&
            addressF && addressF.isValid() &&
            zipcodeF && zipcodeF.isValid() &&
            cityF && cityF.isValid();
    }

    let submitForm = function () {
        if (isFormValid()) {
            // console.log(consultant);
            if (params && params.id) {
                //je suis en modif

                service.update(consultant).then(json => {
                    if (json.success) {
                        setInfosro(true)
                       // console.log(json)
                        // navigate("/consultant");
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                });
            } else {
                //je suis en ajout
                fetch("/api/consultant", {
                    method: "POST",
                    body: JSON.stringify(consultant),
                    headers: {
                        "content-type": 'application/json',
                        "Authorization": "bearer " + localStorage.getItem("token")
                    }
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else if (response.status == 401) {
                            AuthManager.desAuthenticateUser();
                            window.location.href = '/login';
                        }

                    })
                    .then(json => {
                        if (json.success) {
                            navigate("/consultant");
                        } else {
                            alert(json.message)
                        }
                    }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                });
            }
        }


    }
    let [consultant, setConsultant] = useState(consultantToEdit || {
        firstname: "",
        lastname: "",
        login: '',
        Utilisateur: {},
        Missions: []
    });

    let [docs1, setDocs1] = useState({
        cv: {},
        pi: {},
        jd: {},
        ass: {},
        cg: {},
        cm: {},
        ap: {},
        ib: {}
    })
    let service = new ConsultantService();
    let prevServeice = new PrevService()
    useEffect(() => {
        service.getOne(params.id).then(json => {
            setConsultant(json.data)
        })
        service.readDocs1(params.id).then((json => {
            setDocs1(json.data)
        })).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });
    }, []);


    let getConsultantInfosForm = () => {
        return <form className="flex flex-col justify-center p-10">

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Infos du
                    consultant: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                {infosro &&
                    <button
                        className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        title={ `Editer les informations du consultant ${consultant.Utilisateur.firstname} ${consultant.Utilisateur.lastname}` }
                        onClick={e => {
                            setInfosro(!infosro);
                        }}
                        data-cy={"consultant-add-btn"}
                    >{editIcon}</button>}
            </div>


            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

                    {infosro && <>
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-first-name">
                            Nom
                        </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.Utilisateur.firstname || "pas de nom"}</div>
                    </>}
                    {!infosro && <><Field
                        label={"nom"}
                        readOnly={infosro}
                        data-cy={"consultant-firstname"}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        required={true}
                        min={3}
                        max={150}
                        ref={(r) => {
                            firstnameF = r;
                        }}
                        enteraction={submitForm}
                        value={consultant.Utilisateur.firstname || ""}
                        onChange={e => {
                            let c = {...consultant};
                            c.Utilisateur.firstname = e.target.value;
                            setConsultant(c);
                        }}
                        type="text"
                        placeholder="ex: Dupont"/>
                        {/*<p className="text-red-500 text-xs italic">Please fill out this field.</p>*/}
                    </>}
                </div>

                <div className="w-full md:w-1/2 px-3">

                    {infosro && <><label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                         htmlFor="grid-last-name">
                        Prénom
                    </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.Utilisateur.lastname || "pas de prenom"}</div>
                    </>}
                    {!infosro && <Field
                        id="grid-last-name"
                        data-cy={"consultant-lastname"}
                        label={"Prénom"}
                        value={consultant.Utilisateur.lastname || ""}
                        required={true}
                        min={3}
                        max={150}
                        ref={(r) => {
                            lastnameF = r;
                        }}
                        enteraction={submitForm}
                        onChange={e => {
                            let c = {...consultant};
                            c.Utilisateur.lastname = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text" placeholder="ex: Romain"/>}
                </div>
            </div>


            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">
                    {infosro && <>
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-email">
                            email
                        </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">
                            {consultant.Utilisateur.login || "pas d'email "}
                        </div>
                    </>}

                    {!infosro && <><Field
                        label={"email"}
                        id="grid-last-email"
                        data-cy={"consultant-email"}
                        ref={(r) => {
                            emailF = r;
                        }}
                        vtype={"email"}
                        required={true}
                        enteraction={submitForm}
                        value={consultant.Utilisateur.login || ""}
                        onChange={e => {
                            let c = {...consultant};
                            c.Utilisateur.login = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="email" placeholder="ex: user@exaple.com"/></>}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

                    {infosro && <> <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                          htmlFor="grid-city">
                        Téléphone
                    </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">
                            {consultant.Utilisateur.phone || " Aucun telephone "}
                        </div>
                    </>}

                    {!infosro && <><Field
                        label={"téléphone"}
                        data-cy={"consultant-phone"}
                        ref={(r) => {
                            phoneF = r;
                        }}
                        required={true}
                        vtype={"phone"}
                        enteraction={submitForm}
                        value={consultant.Utilisateur.phone || ""}
                        onChange={e => {
                            let c = {...consultant};
                            c.Utilisateur.phone = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-city"
                        placeholder="0600000000"/>
                    </>}

                </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">

                    {infosro && <><label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                         htmlFor="grid-email">
                        Date de naissance
                    </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.birthDate && (new Date(consultant.birthDate)).toLocaleDateString('fr-FR') || "pas de date de naissance"}</div>
                    </>}
                    {!infosro && <DateField
                        data-cy={"Date de naissance"}
                        label={"Date de naissance"}
                        dateFormat="dd/MM/yyyy"
                        locale="fr"
                        required={true}
                        className="block appearance-none w-full bg-gray-200 text-gray-700 border border-green-500 px-4 py-3 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                        id="grid-birth-date"
                        value={consultant.birthDate}
                        enteraction={submitForm}
                        onChange={e => {
                           // console.log(e)
                            let c = {...consultant};
                            c.birthDate = e;
                            setConsultant(c);
                        }}/>}

                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">

                    {infosro && <>  <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-city">
                        Numéro de sécurité sociale
                    </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">
                            {consultant.secuNum || "pas de numero de securite sociale'"}
                        </div>
                    </>}

                    {!infosro && <Field
                        label={"Numéro de sécurité sociale"}
                        data-cy={"consultant-Secu"}
                        ref={(r) => {
                            secuF = r;
                        }}
                        required={true}
                        enteraction={submitForm}
                        value={consultant.secuNum || ""}
                        onChange={e => {
                            let c = {...consultant};
                            c.secuNum = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-city"
                        placeholder="0600000000"/>}

                </div>
            </div>


            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 md:w-1/2">

                    {infosro && <><label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                         htmlFor="grid-email">
                        Adresse complète
                    </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.address || "pas d'adresse'"}</div>
                    </>}

                    {!infosro && <Field
                        label={"Adresse complète"}
                        data-cy={"consultant-addr"}
                        ref={(r) => {
                            addressF = r;
                        }}
                        enteraction={submitForm}

                        required={true}
                        value={consultant.address || ""}
                        onChange={e => {
                            let c = {...consultant};
                            c.address = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-email" type="email" placeholder="ex: 10 rue de la paix"/>}
                </div>
                <div className="w-full px-3 md:w-1/4">

                    {infosro && <> <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                          htmlFor="grid-email">
                        Code postal
                    </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.zipCode || "pas de code postal'"}</div>
                    </>}

                    {!infosro && <Field
                        label={"Code postal"}
                        vtype="codePost"
                        data-cy={"consultant-cp"}
                        ref={(r) => {
                            zipcodeF = r;
                        }}
                        enteraction={submitForm}

                        required={true}
                        value={consultant.zipCode || ""}
                        onChange={e => {
                            let c = {...consultant};
                            c.zipCode = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-email" type="email" placeholder="ex: 78320"/>}
                </div>
                <div className="w-full md:w-1/4 px-3 mb-6 md:mb-0">

                    {infosro && <><label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                         htmlFor="grid-city">
                        Ville
                    </label>
                        <div onDoubleClick={(e) => setInfosro(!infosro)}
                             className="appearance-none block w-full bg-gray-100 text-gray-800 rounded py-3 px-4 mb-3 leading-tight">{consultant.city || "pas de Ville"}</div>
                    </>}

                    {!infosro && <Field
                        label={"ville"}
                        data-cy={"consultant-city"}
                        required={true}
                        ref={(r) => {
                            cityF = r;
                        }}
                        enteraction={submitForm}

                        value={consultant.city || ""}
                        onChange={e => {
                            let c = {...consultant};
                            c.city = e.target.value;
                            setConsultant(c);
                        }}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-city"
                        type="text"
                        pattern={"[0-9]"}
                        step="0.01"
                        placeholder="Ex: Poissy"/>}

                </div>
            </div>


            {!infosro && <div className="pt-5 flex justify-between">
                <button
                    className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        // navigate("/consultant");
                        setInfosro(true)

                    }}
                >{cancelIcon}
                </button>
                <button
                    className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={e => {
                        submitForm();
                        //console.log(consultant);
                    }}
                    data-cy={"consultant-add-btn"}

                >
                    {validateIcon}
                </button>
            </div>}

        </form>

    }
    let sendFileToserver = (doc, type) => {
        //console.log(doc);
        let formData = new FormData();
        formData.append("cid", consultant.id);
        formData.append(type, doc);
        return service.sendDocs1(formData).then(json => {
            if (json.success) {
                let docs = {...docs1}
                docs[type] = doc;
                setDocs1(docs);
            } else {
                setDocs2ro(true);
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });
    }
    let consultantDocsFormValidation = (e) => {

        let formData = new FormData();
        formData.append("cid", consultant.id);
        Object.keys(docs1).forEach((doctype) => {
            formData.append(doctype, docs1[doctype]);
        })
        service.sendDocs1(formData).then(() => {
            if (e.target.dataset.role == "doc1") {
                setDocs1ro(true);
            } else {
                setDocs2ro(true);
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });
    }
    let downloadDoc = (doc) => {
        service.downloadDocs1(doc).then(() => {
            console.log("DOWNLOAD", doc)
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });
    };
    let getConsultantDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents d'identité du
                    consultant: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                {/*{docs1ro && <button*/}
                {/*    className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"*/}
                {/*    type="button"*/}
                {/*    onClick={e => {*/}
                {/*        //console.log(consultant);*/}
                {/*        setDocs1ro(!docs1ro);*/}
                {/*    }}*/}
                {/*    data-cy={"consultant-add-btn"}*/}

                {/*>{editIcon}</button>}*/}
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="docLine">
                    <span>Document</span>
                    <span>Nom du fichier</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                <FileUploader
                    readOnly={docs1ro}
                    label="CV"
                    ondownload={downloadDoc}
                    onpreview={(doc) => {
                        // service.previewDoc(doc)
                        console.log("PREWIEW", doc)
                    }}
                    onChange={(file, e) => {
                        sendFileToserver(file, "cv");
                    }}
                    doc={docs1.cv}/>

                <FileUploader
                    readOnly={docs1ro}
                    female={true}
                    label="Pièce d'identité"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        sendFileToserver(file, "pi")

                    }}
                    doc={docs1.pi}></FileUploader>
                <FileUploader
                    readOnly={docs1ro}
                    label="Justificatif de domicile"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        sendFileToserver(file, "jd")
                        // let docs = {...docs1}
                        // docs.jd = file;
                        // setDocs1(docs);
                    }}
                    doc={docs1.jd}></FileUploader>
                <FileUploader
                    readOnly={docs1ro}
                    female={true}
                    label="Attestation de sécurité sociale"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        sendFileToserver(file, "ass");

                        // let docs = {...docs1}
                        // docs.ass = file;
                        // setDocs1(docs);
                    }}
                    doc={docs1.ass}></FileUploader>
                <FileUploader
                    readOnly={docs1ro}
                    female={true}
                    label="Carte grise"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        sendFileToserver(file, "cg");
                        // let docs = {...docs1}
                        // docs.cg = file;
                        // setDocs1(docs);
                    }}
                    doc={docs1.cg}></FileUploader>
                <FileUploader
                    readOnly={docs1ro}
                    female={true}
                    label="Information bancaire"
                    ondownload={downloadDoc}

                    onChange={(file, e) => {
                        sendFileToserver(file, "ib");
                        // let docs = {...docs1}
                        // docs.ib = file;
                        // setDocs1(docs);
                    }}
                    doc={docs1.ib}></FileUploader>

            </div>
            {/*{!docs1ro && <div className="pt-5 flex justify-between">*/}
            {/*    <button*/}
            {/*        className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"*/}
            {/*        type="button"*/}
            {/*        onClick={e => {*/}
            {/*            // setDocs1({ cv: {}, pi: {}, jd: {}, ass: {}, cg: {}, ib: {} });*/}
            {/*            setDocs1ro(true);*/}
            {/*        }}*/}
            {/*    > {cancelIcon}*/}
            {/*    </button>*/}
            {/*    <button*/}
            {/*        className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"*/}
            {/*        type="button"*/}
            {/*        data-role="doc1"*/}

            {/*        onClick={consultantDocsFormValidation}*/}
            {/*        data-cy={"consultant-add-btn"}>*/}
            {/*        {validateIcon}*/}
            {/*    </button>*/}
            {/*</div>}*/}
        </form>
    }
    let getConsultantHealthDocsForm = () => {
        return <form className="flex flex-col justify-center p-10">


            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>

                <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Documents de Santé du
                    consultant: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                {/*{docs2ro && <button*/}
                {/*    className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"*/}
                {/*    type="button"*/}
                {/*    onClick={e => {*/}
                {/*        setDocs2ro(!docs2ro);*/}
                {/*    }}*/}
                {/*    data-cy={"consultant-add-btn"}*/}

                {/*>{editIcon}</button>}*/}
            </div>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="docLine">
                    <span>Document</span>
                    <span>Nom du fichier</span>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                <FileUploader label="Adhésion mutuelle"
                              readOnly={docs2ro}

                              ondownload={downloadDoc}
                              onChange={(file, e) => {
                                  sendFileToserver(file, "cm");
                                  // let docs = {...docs1}
                                  // docs.cm = file;
                                  // setDocs1(docs);
                              }}
                              doc={docs1.cm}/>
                <FileUploader label="Notice de la prévoyance"
                              readOnly={docs2ro}
                              ondownload={downloadDoc}
                              onChange={(file, e) => {
                                  sendFileToserver(file, "ap");
                                  // let docs = {...docs1}
                                  // docs.ap = file;
                                  // setDocs1(docs);
                              }}
                              doc={docs1.ap}/>
                {/* <FileUploaderRow></FileUploaderRow> */}

            </div>
            {/*{!docs2ro && <div className="pt-5 flex justify-between">*/}
            {/*    <button*/}
            {/*        className="bg-red-500 active:bg-red-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"*/}
            {/*        type="button"*/}
            {/*        onClick={e => {*/}
            {/*            setDocs2ro(true);*/}
            {/*        }}*/}
            {/*    >{cancelIcon}*/}
            {/*    </button>*/}
            {/*    <button*/}
            {/*        className="bg-green-500 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"*/}
            {/*        type="button"*/}
            {/*        data-role="doc2"*/}
            {/*        onClick={consultantDocsFormValidation}*/}
            {/*        data-cy={"consultant-add-btn"}*/}
            {/*    >*/}
            {/*        {validateIcon}*/}
            {/*    </button>*/}
            {/*</div>}*/}

        </form>

    }
    let getCAChart = () => {
        return <form className="flex flex-col justify-center p-10">
            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>CA des 12 derniers
                mois: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
            <div className="flex flex-wrap -mx-3 mb-6">

                <CAChart consultant={consultant}></CAChart>

            </div>
        </form>

    }
    let updateConsultantCra = (cra) => {
        let c = {...consultant};
        for (let i = 0; i < c.Missions.length; i++) {
            if (c.Missions[i].id === cra.MissionId) {
                for (let j = 0; j < c.Missions[i].CraHistories.length; j++) {
                    if (c.Missions[i].CraHistories[j].id === cra.id) {
                        c.Missions[i].CraHistories[j] = cra;
                    }
                }
            }
        }
        return c;
    }
    let uploadNewCra = (cra, crahisto) => {
        //console.log(cra, crahisto)
        const formData = new FormData();
        formData.append('cra', cra);
        formData.append('id', crahisto.id);
        formData.append('missionId', crahisto.MissionId);
        fetch("/api/cra", {
            method: "PUT",
            body: formData,
            headers: {
                "Authorization": "bearer " + localStorage.getItem("token")
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status == 401) {
                    AuthManager.desAuthenticateUser();
                    window.location.href = '/login';
                }

            })
            .then(json => {
               // console.log(json)

                if (json.success) {
                    let c = updateConsultantCra(json.data);
                    setConsultant(c);
                } else {
                    //manage error
                }
            }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });


    }

    let updateConsultantPrev = (prev) => {
        let c = {...consultant};
        for (let i = 0; i < c.Missions.length; i++) {
            if (c.Missions[i].id === prev.MissionId) {
                for (let j = 0; j < c.Missions[i].PrevHistories.length; j++) {
                    if (c.Missions[i].PrevHistories[j].id === prev.id) {
                        c.Missions[i].PrevHistories[j] = prev;
                    }
                }
            }
        }
        return c;
    }
    let updateNewPrev = (prev, setOpen) => {
        prevServeice.insert(prev).then(json => {
            if (json.success) {
                let c = updateConsultantPrev(json.data);
                setConsultant(c);
                setOpen(false);
            } else {
                //manage error
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            })
        });
    }
    let craService = new CraService();
    let editMission = (mission, consul) => {
        mission.Consultant = consul;
        mission.backTo = location.pathname;
        navigate('/missions/update', {state: mission})
    }
    let getMissionView = () => {
        return <>
            <form className="p-10">
                <div className="flex">
                    <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3 flex-1"}>Missions: {consultant.Utilisateur.firstname} {consultant.Utilisateur.lastname}</h3>
                    <div className={""}>
                        <button
                            title={`Creer une mission pour ${consultant.Utilisateur.firstname} ${consultant.Utilisateur.lastname}`}
                            className="bg-green-500 hover:bg-green-700 uppercase text-white ml-2 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                            type="button"
                            onClick={e => {
                                consultant.backTo = location.pathname;
                                navigate('/missions/create', {state: consultant})
                            }}>
                            {addMissionIcon}
                        </button>
                    </div>
                </div>

                <ModalCloseMissionForm
                    getTools={t => {
                        openCloseMissionModal = t.setOpen;
                        setMissionToClose = t.setMission;
                      //  console.log(t.setMission)
                    }}
                    aftersave={cl => {
                    //console.log(cl)
                    let c = {...consultant}
                    let newMissions = c.Missions.map((mission) => {
                        if (mission.id === cl.id) {
                            return cl;
                        } else {
                            return mission;
                        }

                    });
                    c.Missions = newMissions;
                    setConsultant(c);
                }}
                ></ModalCloseMissionForm>
                <MissionTabs
                    onMissionHisto={mission=>{

                    }}

                    onClose={e => {
                        setMissionToClose(e)
                        openCloseMissionModal(true)
                        //console.log(e)
                    }}
                    onEdit={editMission}
                    onPrevChange={updateNewPrev}
                    onFileChange={uploadNewCra} onDownload={doc => {
                    craService.dowmloadCra(doc).then(() => {
                    });
                }} consultant={consultant}></MissionTabs>
            </form>
        </>
    }
    return <Page>
        <div className={"formFull grid grid-cols-2 gap-4 divide-x divide-gray-200 border-b-2 border-gray-200 px-12"}>
            <div role="Form" className={"col-span-2"}>
                {getConsultantInfosForm()}
                {getConsultantDocsForm()}
                {getConsultantHealthDocsForm()}
                {getCAChart()}
                {getMissionView()}
            </div>
        </div>
    </Page>
}
