const { default: AuthManager } = require("../secu/inDbAuth");



function handleJSONResponse(response) {
    return response.json()
        .then(json => {
            if (response.ok) {
                return json;
            } else {
                return Promise.reject({
                    json,
                    status: response.status,
                    statusText: response.statusText
                });
            }
        });
}

function handleTextResponse(response) {
    return response.text()
        .then(text => {
            if (response.ok) {
                return text;
            } else {
                return Promise.reject({
                    status: response.status,
                    statusText: response.statusText,
                    err: text
                });
            }
        });
}



class Service {
    constructor(url) {
        this.url = url;
    }
    handleResponse(response) {
        let contentType = response.headers.get('content-type');
        if (contentType.includes('application/json')) {
            return handleJSONResponse(response);
        } else if (contentType.includes('text/html')) {
            return handleTextResponse(response);
        } else {
            throw new Error(`Sorry, content-type ${contentType} not supported`);
        }
    }
    getAll() {
            return fetch(this.url, {
                method: "GET",
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            }).then(this.handleResponse).catch(error => {
                if (error.status == 401) {
                    AuthManager.desAuthenticateUser();
                    window.location.href = '/login';
                }
            });


    }


    getOne(id) {
        return new Promise((resolve, reject) => {
            fetch(this.url + '/' + id, {
                method: "GET",
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }

    insert(data) {
        return new Promise((resolve, reject) => {
            fetch(this.url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }

    update(data) {
        return new Promise((resolve, reject) => {
            fetch(this.url, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }

    delete(id,data) {
        return new Promise((resolve, reject) => {
            fetch(this.url + '/' + id, {
                method: "DELETE",
                body: JSON.stringify(data),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            }).then(response => {
                if (response.ok) {
                    return response.json();
                } else if (response.status == 401) {
                    AuthManager.desAuthenticateUser();
                    window.location.href = '/login';
                }
            }).then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }
    filterBy(text) {
        return new Promise((resolve, reject) => {
            fetch(this.url + "/filter", {
                method: "POST",
                body: JSON.stringify({ text }),
                headers: {
                    "content-type": 'application/json',
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                })
                .then(jsonresponse => { resolve(jsonresponse); })
                .catch(err => { reject(err); });
        });
    }




    getFile(route) {
        return fetch(this.url + route, {
            method: "GET",
            headers: {
                "content-type": 'application/json',
                "Authorization": "bearer " + localStorage.getItem("token")
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.blob();
                } else if (response.status == 401) {
                    AuthManager.desAuthenticateUser();
                    window.location.href = '/login';
                }

            });

    }

    send(body, method) {
        return new Promise((resolve, reject) => {
            fetch(this.url, {
                method: method || "POST",
                body,
                headers: {
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                })
                .then(blob => { resolve(blob); })
                .catch(err => { reject(err); });
        });
    }

    sendFile(route, body) {
        return new Promise((resolve, reject) => {
            fetch(this.url + route, {
                method: "POST",
                body,
                headers: {
                    "Authorization": "bearer " + localStorage.getItem("token")
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.blob();
                    } else if (response.status == 401) {
                        AuthManager.desAuthenticateUser();
                        window.location.href = '/login';
                    }

                })
                .then(blob => { resolve(blob); })
                .catch(err => { reject(err); });
        });
    }



}
export default Service;
