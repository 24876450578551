import React, {useContext, useState} from 'react'
import {Tab} from '@headlessui/react'
import PrevOverlayForm from "../forms/PrevOverlayForm";
import AuthenticationContext from "../secu/AuthenticationContext";
import CraPrevHistories from "./CraPrevHistories";
import {addIcon, cancelIcon, closeMissionIcon, downloadIcon, editIcon, infoIcon, viewIcon} from "./SvgIcons";
import ModalCrteateActivityForm from "../forms/ModalCrteateActivityForm";
import authManager from "../secu/AuthManager";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Curencify(number) {
    return new Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(number);
}

export default function MissionTabs(props) {
    let setOpenFn, setPrevFn, setOpenPrevHisto, openCreateActiviteModal;
    let downloadPdf = (e) => {
        props.onDownload && props.onDownload(e)
    }
    let AuthContext = useContext(AuthenticationContext);
    let [histoData, setHistoData] = useState({
        title: "",
        actions: []
    });
    let openFileChoser = (crahisto) => {
        let x = document.createElement("input");
        x.setAttribute("type", "file");
        x.setAttribute("style", "display:none");
        x.addEventListener("change", (e) => {
            // console.log(crahisto)
            props.onFileChange && props.onFileChange(x.files[0], crahisto);
        })
        x.click();
    };
    let getClosedMissions = () => {
        return props.consultant.Missions.map((mission) => {
            if (mission.dateEnd != null) {
                return <ul key={mission.id} className={"m-5 p-5"}>
                    <div className="flex">
                        <div className="flex-1">
                            <h3 className={"font-bold text-md uppercase text-gray-400"}>Mission: {mission.id} - {mission.title} - {mission.tjm} €</h3>
                            <h3 className={"font-bold text-md uppercase text-gray-400 mb-1"}>Client: {mission.Client.denominationSocial}</h3>
                        </div>

                        {AuthContext.isAdmin() && <button
                            className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={e => {
                                e.preventDefault();
                                if (props.onEdit && typeof props.onEdit === "function") {
                                    props.onEdit(mission, props.consultant)
                                }
                            }}
                        >
                            {editIcon}
                        </button>}
                        {AuthContext.isAdmin() && <button
                            className="bg-red-600 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={e => {
                                e.preventDefault();
                                if (props.onClose && typeof props.onClose === "function") {
                                    props.onClose(mission, props.Consultant)
                                }
                            }}
                        >
                            {closeMissionIcon}
                        </button>}
                    </div>
                    {/*<h3 className={"font-bold text-md uppercase text-gray-300 mb-3"}>Mission: {mission.id} - {mission.title} - {mission.tjm} €</h3>*/}


                    <br/>
                    <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"

                            >
                                mois/année
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                TJM
                            </th>

                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Prev
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                CA
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                CRA
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">actions</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {mission.PrevHistories.map((prev, i) => {
                            return <tr key={prev.id}>
                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100 "
                                    style={{width: "100px"}}>
                                    <div className="text-sm text-gray-500">{prev.mounth}-{prev.year}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100 "
                                    style={{width: "100px"}}>
                                    <div className="text-sm text-gray-500">{Curencify(mission.tjm)}</div>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100">
                                    <div
                                        className="text-sm text-gray-500">{prev.nbJours == null ? "pas encore saisie" : prev.nbJours + " jours"}
                                        {AuthContext.isAdmin() && <button
                                            className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white ml-2 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            title={"Modifier le Previsionnel"}
                                            onClick={e => {
                                                setPrevFn(prev, i)
                                                setOpenFn(true)
                                            }}
                                        >
                                            {editIcon}

                                        </button>}
                                        {/*{AuthContext.isAdmin() && <button*/}
                                        {/*    title={"Affiche l'hitorique de l'activite Previsionnel"}*/}
                                        {/*    className=" bg-gray-400 active:bg-gray-400 uppercase text-white ml-1 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"*/}
                                        {/*    type="button"*/}
                                        {/*    onClick={(e) => {*/}
                                        {/*        // console.log(mission.PrevHistories[i]);*/}
                                        {/*        return fetch("/api/actions/prev/" + mission.PrevHistories[i].id, {*/}
                                        {/*            method: "GET",*/}
                                        {/*            headers: {*/}
                                        {/*                "content-type": 'application/json',*/}
                                        {/*                "Authorization": "bearer " + localStorage.getItem("token")*/}
                                        {/*            }*/}
                                        {/*        }).then(r => r.json()).then(json => {*/}
                                        {/*            // console.log(json)*/}
                                        {/*            setHistoData({*/}
                                        {/*                title: "HISTORIQUE DE PREV",*/}
                                        {/*                actions: json.data*/}
                                        {/*            })*/}
                                        {/*            setOpenPrevHisto && setOpenPrevHisto(true);*/}
                                        {/*        });*/}


                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    {infoIcon}*/}
                                        {/*</button>}*/}

                                    </div>
                                </td>


                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100 "
                                    style={{width: "200px"}}>
                                    <div
                                        className="text-sm text-gray-500">{Curencify(parseFloat(mission.tjm) * parseFloat(prev.nbJours))}
                                    </div>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100">
                                    <div
                                        className="text-sm text-gray-500">{mission.CraHistories.length == 0 ? "AUCUNE ACTIVITE CRA" : (mission.CraHistories[i].craFilename || "Aucun CRA chargé")}
                                        {AuthContext.isAdmin() && <button
                                            title={"Charger un nouveau CRA"}
                                            className=" bg-yellow-600 hover:bg-yellow-700 uppercase text-white ml-1 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={e => {
                                                if (mission.CraHistories.length == 0) {
                                                    alert("Les Activitees CRA n'on pas encore ete envoye aux consultant")
                                                } else {
                                                    openFileChoser(mission.CraHistories[i])
                                                }
                                            }}
                                        >
                                            {editIcon}
                                        </button>}
                                        {/*{AuthContext.isAdmin() && <button*/}
                                        {/*    title={"Affiche l'hitorique de l'activite Compte rendu d'activite"}*/}
                                        {/*    className=" bg-gray-400 active:bg-gray-400 uppercase text-white ml-1 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"*/}
                                        {/*    type="button"*/}
                                        {/*    onClick={e => {*/}
                                        {/*        return fetch("/api/actions/cra/" + mission.PrevHistories[i].id, {*/}
                                        {/*            method: "GET",*/}
                                        {/*            headers: {*/}
                                        {/*                "content-type": 'application/json',*/}
                                        {/*                "Authorization": "bearer " + localStorage.getItem("token")*/}
                                        {/*            }*/}
                                        {/*        }).then(r => r.json()).then(json => {*/}
                                        {/*            setHistoData({*/}
                                        {/*                title: "HISTORIQUE DE CRA",*/}
                                        {/*                actions: json.data*/}
                                        {/*            })*/}
                                        {/*            setOpenPrevHisto && setOpenPrevHisto(true);*/}
                                        {/*        });*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    {infoIcon}*/}
                                        {/*</button>}*/}

                                    </div>
                                </td>

                                <td className="text-right font-medium w-10 bg-gray-100"
                                    style={{width: "200px"}}>
                                    <button
                                        title={"Telecharger le CRA"}
                                        className="bg-blue-500 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md shadow mr-1 text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={e => {
                                            downloadPdf(mission.CraHistories[i])
                                        }}>
                                        {downloadIcon}
                                    </button>
                                    <button
                                        title={"Visiualiser le CRA"}
                                        className="bg-blue-400 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={e => {
                                            window.open("/api/cra/download/" + mission.CraHistories[i].id);
                                        }}
                                    >
                                        {viewIcon}
                                    </button>
                                    {AuthContext.isAdmin() && <button
                                        title={"Visiualiser le CRA"}
                                        className="bg-gray-400 active:bg-gray-400 uppercase text-white font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={e => {
                                            // props.onMissionHisto && props.onMissionHisto(mission)

                                            return fetch("/api/mission/histo/" + mission.CraHistories[i].id + "/" + mission.PrevHistories[i].id, {
                                                method: "GET",
                                                headers: {
                                                    "content-type": 'application/json',
                                                    "Authorization": "bearer " + localStorage.getItem("token")
                                                }
                                            }).then(r => r.json()).then(json => {
                                                // console.log(json)
                                                setHistoData({
                                                    title: "HISTORIQUE DE L'ACTIVITE",
                                                    actions: json.data
                                                })
                                                setOpenPrevHisto && setOpenPrevHisto(true);
                                            })
                                        }}
                                    >
                                        {infoIcon}
                                    </button>}
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>


                </ul>;
            }

        })


    }
    if (props.consultant.Missions.length === 0) {
        return <div className={"flex justify-center p-10"}>
            <h3 className={"font-bold text-lg uppercase text-gray-400 mb-3"}>Aucune Mission
                pour {props.consultant.Utilisateur && props.consultant.Utilisateur.firstname} {props.consultant.Utilisateur && props.consultant.Utilisateur.lastname}</h3>
        </div>
    }
    return (
        <>
            <CraPrevHistories data={histoData} getTools={({setOpen}) => {
                setOpenPrevHisto = setOpen;
            }}></CraPrevHistories>

            <Tab.Group>
                <div className={"flex flex-wrap -mx-3 mb-6 justify-center"}>

                    <Tab.List className="flex w-full max-w-md  space-x-1 rounded-xl bg-blue-50 p-1">
                        <Tab
                            className={({selected}) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
                                )
                            }
                        >
                            {"Missions en cours"}
                        </Tab>
                        <Tab
                            className={({selected}) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ',
                                    selected
                                        ? 'bg-white shadow'
                                        : 'text-blue-100 hover:bg-white/[0.12] hover:text-blue-500'
                                )
                            }
                        >
                            {"Missions terminées"}
                        </Tab>
                    </Tab.List>
                </div>

                <PrevOverlayForm
                    onValidate={(prev, setOpen) => {
                        props.onPrevChange && props.onPrevChange(prev, setOpen);
                    }}
                    getTools={(o) => {
                        setOpenFn = o.setOpen;
                        setPrevFn = o.setPrev;
                    }}></PrevOverlayForm>
                <Tab.Panels className="mt-2">
                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white p-3 divide-y-4 divide-solid divide-blue-200',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}
                    >
                        {props.consultant.Missions.map((mission) => {
                            if (mission.dateEnd === null) {

                                return <ul key={mission.id} className={"m-5 p-5"}>
                                    <ModalCrteateActivityForm
                                        getTools={t => {
                                            openCreateActiviteModal = t.setOpen;
                                            t.setMission(mission)
                                            //console.log(t.setMission)
                                        }} aftersave={cl => {
                                        console.log(cl)
                                        //setEmployeur(cl);
                                    }}
                                    ></ModalCrteateActivityForm>
                                    <div className="flex">
                                        <div className="flex-1">
                                            <h3 className={"font-bold text-md uppercase text-gray-400"}>Mission: {mission.id} - {mission.title} - {mission.tjm} €</h3>
                                            <h3 className={"font-bold text-md uppercase text-gray-400 mb-1"}>Client: {mission.Client.denominationSocial}</h3>
                                        </div>
                                        {AuthContext.isAdmin() && <button
                                            title={"Creer les avtivitees prev et cra pour cette mission"}
                                            className="bg-green-600 hover:bg-green-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={e => {
                                                openCreateActiviteModal(true);
                                            }}
                                        >
                                            {addIcon}
                                        </button>}
                                        {AuthContext.isAdmin() && <button
                                            className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            title={"Editer cette mission"}

                                            onClick={e => {
                                                e.preventDefault();
                                                if (props.onEdit && typeof props.onEdit === "function") {
                                                    props.onEdit(mission, props.consultant)
                                                }
                                            }}
                                        >
                                            {editIcon}
                                        </button>}
                                        {AuthContext.isAdmin() && <button
                                            className="bg-red-600 hover:bg-red-700 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            title={"Cloturer cette mission"}
                                            onClick={e => {
                                                e.preventDefault();
                                                if (props.onClose && typeof props.onClose === "function") {
                                                    props.onClose(mission, props.Consultant)
                                                }
                                            }}
                                        >
                                            {closeMissionIcon}
                                        </button>}
                                    </div>


                                    <br/>
                                    <table className="min-w-full divide-y divide-gray-200 ">
                                        <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"

                                            >
                                                mois/annee
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                TJM
                                            </th>

                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Prev
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                CA
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                CRA
                                            </th>
                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">actions</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                        {mission.PrevHistories.map((prev, i) => {
                                            return <tr key={prev.id}>
                                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100 "
                                                    style={{width: "200px"}}>
                                                    <div
                                                        className="text-sm text-gray-500">{prev.mounth}-{prev.year}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100 "
                                                    style={{width: "200px"}}>
                                                    <div
                                                        className="text-sm text-gray-500">{Curencify(mission.tjm)}</div>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100">
                                                    <div
                                                        className="text-sm text-gray-500">{prev.nbJours == null ? "pas encore saisie" : prev.nbJours + " jours"}
                                                        <button
                                                            className="bg-yellow-600 hover:bg-yellow-700 uppercase text-white ml-2 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                                            type="button"
                                                            title={"Modifier le Previsionnel"}
                                                            onClick={e => {
                                                                setPrevFn(prev, i)
                                                                setOpenFn(true)
                                                            }}
                                                        >
                                                            {editIcon}
                                                            {/*<svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3"*/}
                                                            {/*     fill="none" viewBox="0 0 24 24" stroke="currentColor"*/}
                                                            {/*     strokeWidth={2}>*/}
                                                            {/*    <path strokeLinecap="round" strokeLinejoin="round"*/}
                                                            {/*          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>*/}
                                                            {/*</svg>*/}
                                                        </button>
                                                        {/*{AuthContext.isAdmin() && <button*/}
                                                        {/*    title={"Affiche l'hitorique de l'activite Previsionnel"}*/}
                                                        {/*    className=" bg-gray-400 active:bg-gray-400 uppercase text-white ml-1 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"*/}
                                                        {/*    type="button"*/}
                                                        {/*    onClick={(e) => {*/}
                                                        {/*        // console.log(mission.PrevHistories[i]);*/}
                                                        {/*        return fetch("/api/actions/prev/" + mission.PrevHistories[i].id, {*/}
                                                        {/*            method: "GET",*/}
                                                        {/*            headers: {*/}
                                                        {/*                "content-type": 'application/json',*/}
                                                        {/*                "Authorization": "bearer " + localStorage.getItem("token")*/}
                                                        {/*            }*/}
                                                        {/*        }).then(r => r.json()).then(json => {*/}
                                                        {/*            // console.log(json)*/}
                                                        {/*            setHistoData({*/}
                                                        {/*                title: "HISTORIQUE DE PREV",*/}
                                                        {/*                actions: json.data*/}
                                                        {/*            })*/}
                                                        {/*            setOpenPrevHisto && setOpenPrevHisto(true);*/}
                                                        {/*        });*/}


                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    {infoIcon}*/}
                                                        {/*</button>}*/}

                                                    </div>
                                                </td>


                                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100 "
                                                    style={{width: "200px"}}>
                                                    <div
                                                        className="text-sm text-gray-500">{prev.nbJours && Curencify(parseFloat(mission.tjm) * parseFloat(prev.nbJours))}
                                                    </div>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap bg-gray-100">
                                                    <div
                                                        className="text-sm text-gray-500">{mission.CraHistories.length == 0 ? "AUCUNE ACTIVITE CRA" : (mission.CraHistories[i].craFilename || "Aucun CRA chargé")}
                                                        <button
                                                            title={"Charger un nouveau CRA"}
                                                            className=" bg-yellow-600 hover:bg-yellow-700 uppercase text-white ml-1 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                                            type="button"
                                                            onClick={e => {
                                                                if (mission.CraHistories.length == 0) {
                                                                    alert("Les Activitées CRA n'on pas encore été envoyés aux consultant")
                                                                } else {
                                                                    openFileChoser(mission.CraHistories[i])
                                                                }
                                                            }}
                                                        >
                                                            {editIcon}
                                                        </button>
                                                        {/*{AuthContext.isAdmin() && <button*/}
                                                        {/*    title={"Affiche l'hitorique de l'activite Compte rendu d'activite"}*/}
                                                        {/*    className=" bg-gray-400 active:bg-gray-400 uppercase text-white ml-1 font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"*/}
                                                        {/*    type="button"*/}
                                                        {/*    onClick={e => {*/}
                                                        {/*        return fetch("/api/actions/cra/" + mission.PrevHistories[i].id, {*/}
                                                        {/*            method: "GET",*/}
                                                        {/*            headers: {*/}
                                                        {/*                "content-type": 'application/json',*/}
                                                        {/*                "Authorization": "bearer " + localStorage.getItem("token")*/}
                                                        {/*            }*/}
                                                        {/*        }).then(r => r.json()).then(json => {*/}
                                                        {/*            setHistoData({*/}
                                                        {/*                title: "HISTORIQUE DE CRA",*/}
                                                        {/*                actions: json.data*/}
                                                        {/*            })*/}
                                                        {/*            setOpenPrevHisto && setOpenPrevHisto(true);*/}
                                                        {/*        });*/}
                                                        {/*    }}*/}
                                                        {/*>*/}
                                                        {/*    {infoIcon}*/}
                                                        {/*</button>}*/}

                                                    </div>
                                                </td>

                                                <td className="text-right font-medium w-10 bg-gray-100"
                                                    style={{width: "200px"}}>
                                                    <button
                                                        title={"Telecharger le CRA"}
                                                        className="bg-blue-500 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md shadow mr-1 text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={e => {
                                                            downloadPdf(mission.CraHistories[i])
                                                        }}>
                                                        {downloadIcon}
                                                    </button>
                                                    <button
                                                        title={"Visiualiser le CRA"}
                                                        className="bg-blue-400 active:bg-blue-600 uppercase text-white font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={e => {
                                                            window.open("/api/cra/download/" + mission.CraHistories[i].id);
                                                        }}
                                                    >
                                                        {viewIcon}
                                                    </button>
                                                    {AuthContext.isAdmin() && <button
                                                        title={"Visiualiser le CRA"}
                                                        className="bg-gray-400 active:bg-gray-400 uppercase text-white font-bold hover:shadow-md mr-1 shadow text-xs px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                                        type="button"
                                                        onClick={e => {
                                                            // props.onMissionHisto && props.onMissionHisto(mission)

                                                            return fetch("/api/mission/histo/" + mission.CraHistories[i].id + "/" + mission.PrevHistories[i].id, {
                                                                method: "GET",
                                                                headers: {
                                                                    "content-type": 'application/json',
                                                                    "Authorization": "bearer " + localStorage.getItem("token")
                                                                }
                                                            }).then(r => r.json()).then(json => {
                                                                // console.log(json)
                                                                setHistoData({
                                                                    title: "HISTORIQUE DE PREV",
                                                                    actions: json.data
                                                                })
                                                                setOpenPrevHisto && setOpenPrevHisto(true);
                                                            })
                                                        }}
                                                    >
                                                        {infoIcon}
                                                    </button>}
                                                </td>
                                            </tr>;
                                        })}
                                        </tbody>
                                    </table>


                                </ul>;
                            }


                        })}


                    </Tab.Panel>

                    <Tab.Panel
                        className={classNames(
                            'rounded-xl bg-white p-3',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 divide-y-4 divide-solid divide-blue-200'
                        )}
                    >
                        {/*<ul className={'w-full'}>*/}
                        {getClosedMissions()}
                        {/*</ul>*/}
                    </Tab.Panel>

                </Tab.Panels>
            </Tab.Group>
        </>
    )
}
