import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ConsultantService from "../Services/ConsultantService";
import ConsultantGrid from "../mini/ConsultantGrid";
import Page from "./Page";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Loader from "../../tools/Loader";
import {addIcon} from "../mini/SvgIcons";
const MySwal = withReactContent(Swal)

export default function Consultants() {
    const navigate = useNavigate();
    let [consultants, setConsultants] = useState([]);
    let location = useLocation();
    let service = new ConsultantService();
    useEffect(() => {
        Loader.loadstart('chargement de la liste des consultants')
        service.getAll().then(json => {
            if (json.success) {
                setConsultants(json.data);
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: json.message,
                });
            }
        }).catch(err => {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.message,
            });
        }).finally(() => {
            Loader.loadstop();
        });
    }, [])

    let deleteConsultant = (consultant) => {
        MySwal.fire({
            title: `Suppression de ${consultant.Utilisateur.firstname} ${consultant.Utilisateur.lastname} ?`,
            text: "Êtes-vous sûr de vouloir  supprimer définitivement le consultant!",
            //type: "error",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Oui, je supprime !",
            cancelButtonText: "Non, je ne supprime pas !",
            // closeOnConfirm: false
        }).then((res) => {
            if (res.isConfirmed) {
                Loader.loadstart( `Suppression de ${consultant.Utilisateur.firstname} ${consultant.Utilisateur.lastname} ... `)
                service.delete(consultant.id).then(json => {
                    if (json.success) {
                        let e = consultants
                        e = e.filter((em) => {
                            return em.id != consultant.id
                        })
                        setConsultants(e)
                    } else {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: json.message,
                        })
                    }
                }).catch(err => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: err.message,
                    })
                }).finally(() => {
                    Loader.loadstop();
                });
            }
        })

    };

    let openMissionForm = (consultant) => {
        consultant.backTo = location.pathname;
        navigate('/missions/create', {state: consultant})
    };

    return <Page>
        <div className="pt-2 sm:px-6 lg:px-8">
            <button
                className="bg-green-500 hover:bg-green-700 active:bg-green-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                type="button"
                title={"Créer un nouveau consultant"}
                onClick={() => {
                    navigate('/consultant/create')
                }}
                data-cy={"New-Consultant-Btn"}
            >
                {addIcon}
            </button>
        </div>
        <ConsultantGrid
            consultants={consultants}
            onmission={openMissionForm}
            onEdit={consultant => {
                consultant.backTo = location.pathname;
                navigate("/consultant/create/" + consultant.id, {state: consultant})
            }}
            onDelete={deleteConsultant}
        />
    </Page>;
}
