import Service from './Service';
class MissionsService extends Service {
    constructor(){
        super("/api/mission");
    }
    getHistory(mission) {
        return fetch(this.url+"/histo/"+mission.id, {
            method: "GET",
            headers: {
                "content-type": 'application/json',
                "Authorization": "bearer " + localStorage.getItem("token")
            }
        }).then(this.handleResponse).catch(error => {
            if (error.status == 401) {
                AuthManager.desAuthenticateUser();
                window.location.href = '/login';
            }
        });


    }

}
export default MissionsService;